import { JsonRpcProvider } from '@ethersproject/providers';

export const getJsonRPCProvider = (
  rpcUrl: string,
): Promise<JsonRpcProvider> => {
  return Promise.resolve(
    new JsonRpcProvider({
      url: rpcUrl,
    }),
  );
};
