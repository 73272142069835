import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import axiosRetry from 'axios-retry';

const logResponse = (response: AxiosResponse) => {
  console.log(`RESPONSE:\n ${response.status} ${response.statusText}`);
};
const logErrorResponse = (error: AxiosError) => {
  console.log(
    `RESPONSE:\n ${error?.response?.status} ${error?.response?.statusText}`,
  );
};

const logRequest = request => {
  console.log(
    `REQUEST:\n ${request.method?.toUpperCase()} ${request.baseURL}${
      request.url
    }`,
  );
};

export const getAxiosWithDebugLogger = (
  enableDebug: boolean,
): AxiosInstance => {
  const axiosInstance = axios.create();

  // Retry on network errors or throttles
  axiosRetry(axiosInstance, {
    retries: 3,
    retryDelay: axiosRetry.exponentialDelay,
    retryCondition: error => {
      return axiosRetry.isNetworkError(error) || error.response!.status === 429;
    },
  });

  axiosInstance.interceptors.response.use(
    response => {
      if (enableDebug) {
        logResponse(response);
      }

      return response;
    },
    error => {
      if (enableDebug) {
        logErrorResponse(error);
      }
      return Promise.reject(error);
    },
  );
  axiosInstance.interceptors.request.use(
    request => {
      if (enableDebug) {
        logRequest(request);
      }
      return request;
    },
    error => {
      if (enableDebug) {
        logRequest(error);
      }
      return Promise.reject(error);
    },
  );

  return axiosInstance;
};
