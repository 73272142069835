/**
 * NOTE! This file has grown too large. Api types can be organised better by domain separation.
 * If working on an existing domain, please remember to clean up as you go.
 * Simply add a new domain file in this folder and update the index file.
 */
import * as t from 'io-ts';
import { DateFromISOString } from 'io-ts-types';

import {
  ERC20TokenTypeT,
  ERC721TokenTypeT,
  EthAddress,
  ETHTokenTypeT,
  FeeCodec,
  fromEnum,
  HexadecimalString,
  MintBodyCodec,
  MintV2BodyCodec,
  NonNegativeBigNumber,
  OrderParamsCodec,
  PositiveBigNumber,
  PositiveIntegerC,
  PositiveIntegerStringC,
  TokenCodec,
  TokenTypeCodec,
  TransferParamsCodec,
} from '../runtime';

export enum ImmutableOrderStatus {
  created = 'created',
  accepted = 'accepted',
  partially_filled = 'partially_filled',
  filled = 'filled',
  cancelled = 'cancelled',
  expired = 'expired',
  pending = 'pending',
  active = 'active',
  inactive = 'inactive',
}

export enum ImmutableOrderMakerTakerType {
  maker = 'maker',
  taker = 'taker',
}

/**
 * NOTE! fiat_to_crypto and crypto_to_fiat types are deprecated and will be removed
 */
export enum ImmutableExchangeType {
  onramp = 'fiat-to-crypto',
  offramp = 'crypto-to-fiat',
  fiat_to_crypto = 'fiat-to-crypto',
  crypto_to_fiat = 'crypto-to-fiat',
}

export enum ImmutableExchangeTypeV3 {
  onramp = 'onramp',
  offramp = 'offramp',
}

export const ImmutableOrderStatusCodec = fromEnum<ImmutableOrderStatus>(
  'ImmutableOrderStatus',
  ImmutableOrderStatus,
);

export const ImmutableOrderMakerTakerTypeCodec =
  fromEnum<ImmutableOrderMakerTakerType>(
    'ImmutableOrderMakerTakerType',
    ImmutableOrderMakerTakerType,
  );

export enum ImmutableTransactionStatus {
  pending = 'pending',
  accepted = 'accepted',
  rejected = 'rejected',
  confirmed = 'confirmed',
  rolledBack = 'rolledBack',
  withdrawn = 'withdrawn',
  success = 'success',
}

export const ImmutableTransactionStatusCodec =
  fromEnum<ImmutableTransactionStatus>(
    'ImmutableTransactionStatus',
    ImmutableTransactionStatus,
  );

export enum ImmutableAssetStatus {
  imx = 'imx',
  eth = 'eth',
  preparing_withdrawal = 'preparing_withdrawal',
  withdrawable = 'withdrawable',
  burned = 'burned',
}

export const ImmutableAssetStatusCodec = fromEnum<ImmutableAssetStatus>(
  'ImmutableAssetStatus',
  ImmutableAssetStatus,
);

export enum ImmutableRollupStatus {
  included = 'included',
  confirmed = 'confirmed',
}

export const ImmutableRollupStatusCodec = fromEnum<ImmutableRollupStatus>(
  'ImmutableRollupStatus',
  ImmutableRollupStatus,
);

export enum ImmutableFeeType {
  royalty = 'royalty',
  ecosystem = 'ecosystem',
  protocol = 'protocol',
  maker = 'maker',
  taker = 'taker',
}

export const ImmutableFeeTypeCodec = fromEnum<ImmutableFeeType>(
  'ImmutableFeeType',
  ImmutableFeeType,
);

export const ImmutableFeeInfoCodec = t.type({
  fee_limit: PositiveBigNumber,
  source_vault_id: t.Int,
  asset_id: HexadecimalString,
});

export namespace ImmutableMethodParams {
  export const ImmutableRegisterParamsCodec = t.intersection([
    t.type({
      etherKey: EthAddress,
      starkPublicKey: HexadecimalString,
    }),
    t.partial({
      email: t.string,
    }),
  ]);

  export const ImmutableSignParamsCodec = t.string;

  export type ImmutableSignParams = t.TypeOf<typeof ImmutableSignParamsCodec>;
  export type ImmutableRegisterParams = t.TypeOf<
    typeof ImmutableRegisterParamsCodec
  >;
  export type ImmutableRegisterParamsTS = t.OutputOf<
    typeof ImmutableRegisterParamsCodec
  >;

  export const ImmutableStarkRegisterParamsCodec = t.type({
    etherKey: EthAddress,
    starkPublicKey: HexadecimalString,
    operatorSignature: t.string,
  });
  export type ImmutableStarkRegisterParams = t.TypeOf<
    typeof ImmutableStarkRegisterParamsCodec
  >;
  export type ImmutableStarkRegisterParamsTS = t.OutputOf<
    typeof ImmutableStarkRegisterParamsCodec
  >;

  export const ImmutableGetUserParamsCodec = t.type({
    user: EthAddress,
  });
  export type ImmutableGetUserParams = t.TypeOf<
    typeof ImmutableGetUserParamsCodec
  >;
  export type ImmutableGetUserParamsTS = t.OutputOf<
    typeof ImmutableGetUserParamsCodec
  >;

  export const ImmutableGetSignableDepositParamsCodec = t.type({
    user: EthAddress,
    token: TokenCodec,
    quantity: PositiveBigNumber,
  });
  export type ImmutableGetSignableDepositParams = t.TypeOf<
    typeof ImmutableGetSignableDepositParamsCodec
  >;
  export type ImmutableGetSignableDepositParamsTS = t.OutputOf<
    typeof ImmutableGetSignableDepositParamsCodec
  >;

  export const ImmutableGetSignableRegistrationParamsCodec = t.type({
    etherKey: EthAddress,
    starkPublicKey: HexadecimalString,
  });
  export type ImmutableGetSignableRegistrationParams = t.TypeOf<
    typeof ImmutableGetSignableRegistrationParamsCodec
  >;
  export type ImmutableGetSignableRegistrationParamsTS = t.OutputOf<
    typeof ImmutableGetSignableRegistrationParamsCodec
  >;

  export const ImmutableDepositCancelParamsCodec = t.type({
    starkPublicKey: HexadecimalString,
    token: TokenCodec,
    vaultId: PositiveIntegerStringC,
  });
  export type ImmutableDepositCancelParams = t.TypeOf<
    typeof ImmutableDepositCancelParamsCodec
  >;

  export const ImmutableDepositReclaimParamsCodec = t.type({
    starkPublicKey: HexadecimalString,
    token: TokenCodec,
    vaultId: PositiveIntegerStringC,
  });
  export type ImmutableDepositReclaimParams = t.TypeOf<
    typeof ImmutableDepositReclaimParamsCodec
  >;

  export const ImmutableWithdrawalParamsCodec = t.type({
    starkPublicKey: HexadecimalString,
    quantity: PositiveBigNumber,
    token: TokenCodec,
    vaultId: PositiveIntegerStringC,
    nonce: PositiveIntegerStringC,
  });
  export type ImmutableWithdrawalParams = t.TypeOf<
    typeof ImmutableWithdrawalParamsCodec
  >;

  export const ImmutableGetSignableWithdrawalParamsCodec = t.type({
    user: EthAddress,
    token: TokenCodec,
    quantity: PositiveBigNumber,
  });
  export type ImmutableGetSignableWithdrawalParams = t.TypeOf<
    typeof ImmutableGetSignableWithdrawalParamsCodec
  >;

  export const ImmutableTransferParamsCodec = t.type({
    sender: EthAddress,
    token: TokenCodec,
    quantity: PositiveBigNumber,
    receiver: EthAddress,
  });
  export type ImmutableTransferParams = t.TypeOf<
    typeof ImmutableTransferParamsCodec
  >;
  export type ImmutableTransferParamsTS = t.OutputOf<
    typeof ImmutableTransferParamsCodec
  >;

  export const SignableTransferV2Codec = t.type({
    receiver: EthAddress,
    token: TokenCodec,
    amount: t.string,
  });
  export const ImmutableGetSignableTransferV2ParamsCodec = t.type({
    sender_ether_key: EthAddress,
    signable_requests: t.array(SignableTransferV2Codec),
  });
  export type ImmutableGetSignableTransferV2Params = t.TypeOf<
    typeof ImmutableGetSignableTransferV2ParamsCodec
  >;
  export type ImmutableGetSignableTransferV2ParamsTS = t.OutputOf<
    typeof ImmutableGetSignableTransferV2ParamsCodec
  >;

  export const ImmutableTransferRequestV2Codec = t.type({
    stark_signature: t.string,
    sender_vault_id: t.Int,
    receiver_stark_key: HexadecimalString,
    receiver_vault_id: t.Int,
    amount: t.string,
    asset_id: HexadecimalString,
    expiration_timestamp: t.Int,
    nonce: t.Int,
  });
  export type ImmutableTransferRequestV2 = t.TypeOf<
    typeof ImmutableTransferRequestV2Codec
  >;
  export const ImmutableTransferRequestCodec = t.type({
    token: TokenCodec,
    amount: PositiveBigNumber,
    receiver: EthAddress,
  });
  export type ImmutableTransferRequest = t.TypeOf<
    typeof ImmutableTransferRequestCodec
  >;
  export type ImmutableTransferRequestTS = t.OutputOf<
    typeof ImmutableTransferRequestCodec
  >;

  // These types are for the function params, not API request params
  export const ImmutableTransferV2ParamsCodec = t.type({
    sender_ether_key: EthAddress,
    transfer_request: t.array(ImmutableTransferRequestCodec),
  });
  export type ImmutableTransferV2Params = t.TypeOf<
    typeof ImmutableTransferV2ParamsCodec
  >;
  export type ImmutableTransferV2ParamsTS = t.OutputOf<
    typeof ImmutableTransferV2ParamsCodec
  >;

  export const ImmutableBurnParamsCodec = t.type({
    sender: EthAddress,
    token: TokenCodec,
    quantity: PositiveBigNumber,
  });
  export type ImmutableBurnParams = t.TypeOf<typeof ImmutableBurnParamsCodec>;
  export type ImmutableBurnParamsTS = t.OutputOf<
    typeof ImmutableBurnParamsCodec
  >;

  export const ImmutableOffchainMintParamsCodec = t.type({
    mints: t.array(MintBodyCodec),
  });
  export type ImmutableOffchainMintParams = t.TypeOf<
    typeof ImmutableOffchainMintParamsCodec
  >;
  export type ImmutableOffchainMintParamsTS = t.OutputOf<
    typeof ImmutableOffchainMintParamsCodec
  >;

  // TODO: Remove V2 once V1 is deprecated
  export const ImmutableOffchainMintV2ParamsCodec = t.array(MintV2BodyCodec);
  export type ImmutableOffchainMintV2Params = t.TypeOf<
    typeof ImmutableOffchainMintV2ParamsCodec
  >;
  export type ImmutableOffchainMintV2ParamsTS = t.OutputOf<
    typeof ImmutableOffchainMintV2ParamsCodec
  >;

  export const ImmutableCreateOrderParamsCodec = t.type({
    starkPublicKey: HexadecimalString,
    sell: OrderParamsCodec,
    buy: OrderParamsCodec,
    nonce: PositiveIntegerStringC,
    expirationTimestamp: PositiveIntegerC,
    signature: t.string,
  });
  export type ImmutableCreateOrderParams = t.TypeOf<
    typeof ImmutableCreateOrderParamsCodec
  >;

  export const ImmutableCancelOrderParamsCodec = t.Int;
  export type ImmutableCancelOrderParamsTS = t.OutputOf<
    typeof ImmutableCancelOrderParamsCodec
  >;

  export const ImmutableGetSignableOrderParamsCodec = t.intersection([
    t.type({
      user: EthAddress,
      tokenSell: TokenCodec,
      amountSell: PositiveBigNumber,
      tokenBuy: TokenCodec,
      amountBuy: PositiveBigNumber,
    }),
    t.partial({
      /** v1 vs v2. */
      include_fees: t.boolean,
      /** Maker fees. */
      fees: t.array(FeeCodec),
      /** v2 vs v3. */
      splitFees: t.boolean,
      expiration_timestamp: PositiveIntegerC,
    }),
  ]);

  export type ImmutableGetSignableOrderParams = t.TypeOf<
    typeof ImmutableGetSignableOrderParamsCodec
  >;
  export type ImmutableGetSignableOrderParamsTS = t.OutputOf<
    typeof ImmutableGetSignableOrderParamsCodec
  >;

  export const ImmutableGetSignableTradeParamsCodec = t.intersection([
    t.type({
      orderId: t.Int,
    }),
    ImmutableGetSignableOrderParamsCodec,
  ]);
  export type ImmutableGetSignableTradeParams = t.TypeOf<
    typeof ImmutableGetSignableTradeParamsCodec
  >;
  export type ImmutableGetSignableTradeParamsTS = t.OutputOf<
    typeof ImmutableGetSignableTradeParamsCodec
  >;

  export const ImmutableGetSignableTradeV3ParamsCodec = t.intersection([
    t.type({
      orderId: t.Int,
      user: EthAddress,
    }),
    t.partial({
      include_fees: t.boolean,
      fees: t.array(FeeCodec),
      expiration_timestamp: PositiveIntegerC,
    }),
  ]);
  export type ImmutableGetSignableTradeV3Params = t.TypeOf<
    typeof ImmutableGetSignableTradeV3ParamsCodec
  >;
  export type ImmutableGetSignableTradeV3ParamsTS = t.OutputOf<
    typeof ImmutableGetSignableTradeV3ParamsCodec
  >;

  // not used in sdk as we directly call create trade with the response from signable order
  export const ImmutableCreateTradeParamsCodec = t.intersection([
    t.type({
      starkPublicKey: HexadecimalString,
      sell: OrderParamsCodec,
      buy: OrderParamsCodec,
      nonce: PositiveIntegerStringC,
      expirationTimestamp: PositiveIntegerStringC,
      signature: t.string,
      orderId: PositiveIntegerStringC,
    }),
    t.partial({ fee_info: ImmutableFeeInfoCodec }),
  ]);
  export type ImmutableCreateTradeParams = t.TypeOf<
    typeof ImmutableCreateTradeParamsCodec
  >;

  export const ImmutableGetTokenParamsCodec = t.partial({
    /**
     * NOTE: 't.union([t.string, EthAddress])' is not used
     * here because 'tokenAddress' value defaults to 'eth'.
     */
    tokenAddress: EthAddress,
  });
  export type ImmutableGetTokenParams = t.TypeOf<
    typeof ImmutableGetTokenParamsCodec
  >;
  export type ImmutableGetTokenParamsTS = t.OutputOf<
    typeof ImmutableGetTokenParamsCodec
  >;

  export const ImmutableListTokensParamsCodec = t.partial({
    symbols: t.array(t.string),
    cursor: t.string,
  });
  export type ImmutableListTokensParams = t.TypeOf<
    typeof ImmutableListTokensParamsCodec
  >;
  export type ImmutableListTokensParamsTS = t.OutputOf<
    typeof ImmutableListTokensParamsCodec
  >;

  // TODO: v1 endpoint to get balance, remove when it is deprecated
  export const ImmutableGetBalancesParamsCodec = t.type({
    user: EthAddress,
  });
  export type ImmutableGetBalancesParams = t.TypeOf<
    typeof ImmutableGetBalancesParamsCodec
  >;
  export type ImmutableGetBalancesParamsTS = t.OutputOf<
    typeof ImmutableGetBalanceParamsCodec
  >;

  export const ImmutableGetBalanceParamsCodec = t.type({
    user: EthAddress,
    tokenAddress: t.union([t.string, EthAddress]),
  });
  export type ImmutableGetBalanceParams = t.TypeOf<
    typeof ImmutableGetBalanceParamsCodec
  >;
  export type ImmutableGetBalanceParamsTS = t.OutputOf<
    typeof ImmutableGetBalanceParamsCodec
  >;

  export const ImmutableListBalancesParamsCodec = t.intersection([
    t.type({ user: EthAddress }),
    t.partial({ cursor: t.string, symbols: t.array(t.string) }),
  ]);
  export type ImmutableListBalancesParams = t.TypeOf<
    typeof ImmutableListBalancesParamsCodec
  >;
  export type ImmutableListBalancesParamsTS = t.OutputOf<
    typeof ImmutableListBalancesParamsCodec
  >;

  export const ImmutableGetVaultsParamsCodec = t.type({
    starkPublicKey: HexadecimalString,
  });
  export type ImmutableGetVaultsParams = t.TypeOf<
    typeof ImmutableGetVaultsParamsCodec
  >;

  export const ImmutableGetOrderParamsCodec = t.intersection([
    t.type({
      orderId: t.Int,
    }),
    t.partial({
      include_fees: t.boolean,
    }),
    t.partial({
      /** Modifies the order amount in the response to be inclusive of these fees. */
      auxiliaryFees: t.array(FeeCodec),
    }),
  ]);
  export type ImmutableGetOrderParams = t.TypeOf<
    typeof ImmutableGetOrderParamsCodec
  >;
  export type ImmutableGetOrderParamsTS = t.OutputOf<
    typeof ImmutableGetOrderParamsCodec
  >;

  export const ImmutableGetLastAvailableNonceParamsCodec = t.type({
    starkPublicKey: HexadecimalString,
  });
  export type ImmutableGetLastAvailableNonceParams = t.TypeOf<
    typeof ImmutableGetLastAvailableNonceParamsCodec
  >;

  export enum ImmutableSortOrder {
    asc = 'asc',
    desc = 'desc',
  }

  export const ImmutableSortOrderCodec = fromEnum<ImmutableSortOrder>(
    'ImmutableSortOrder',
    ImmutableSortOrder,
  );

  export const ImmutablePaginatedParamsCodec = t.partial({
    order_by: t.string,
    page_size: t.Int,
    cursor: t.string,
    direction: ImmutableSortOrderCodec,
  });
  export type ImmutablePaginatedParams = t.TypeOf<
    typeof ImmutablePaginatedParamsCodec
  >;
  export type ImmutablePaginatedParamsTS = t.OutputOf<
    typeof ImmutablePaginatedParamsCodec
  >;

  export const ImmutableGetOrdersParamsCodec = t.intersection([
    ImmutablePaginatedParamsCodec,
    t.partial({
      user: EthAddress,
      status: ImmutableOrderStatusCodec,
      /** filter on the created_at field */
      min_timestamp: t.string,
      /** filter on the created_at field */
      max_timestamp: t.string,
      buy_token_type: TokenTypeCodec,
      buy_token_id: t.string,
      buy_token_address: t.union([EthAddress, t.string]),
      buy_token_name: t.string,
      buy_min_quantity: PositiveIntegerStringC,
      buy_max_quantity: PositiveIntegerStringC,
      buy_metadata: t.string,
      sell_token_type: TokenTypeCodec,
      sell_token_id: t.string,
      sell_token_address: t.union([EthAddress, t.string]),
      sell_token_name: t.string,
      sell_min_quantity: PositiveIntegerStringC,
      sell_max_quantity: PositiveIntegerStringC,
      sell_metadata: t.string,
      include_fees: t.boolean,
      /** Modifies the order amounts in the response to be inclusive of these fees. */
      auxiliaryFees: t.array(FeeCodec),
      /** filter on the updated_at field */
      updated_min_timestamp: t.string,
      /** filter on the updated_at field */
      updated_max_timestamp: t.string,
    }),
  ]);
  export type ImmutableGetOrdersParams = t.TypeOf<
    typeof ImmutableGetOrdersParamsCodec
  >;
  export type ImmutableGetOrdersParamsTS = t.OutputOf<
    typeof ImmutableGetOrdersParamsCodec
  >;

  export const ImmutableGetAssetsParamsCodec = t.intersection([
    ImmutablePaginatedParamsCodec,
    t.partial({
      user: EthAddress,
      status: ImmutableAssetStatusCodec,
      metadata: t.string,
      collection: EthAddress,
      name: t.string,
      sell_orders: t.boolean,
      buy_orders: t.boolean,
      include_fees: t.boolean,
      /** When sell_orders=true order amounts in the response are adjusted to be inclusive of these fees. */
      auxiliaryFees: t.array(FeeCodec),
    }),
  ]);
  export type ImmutableGetAssetsParams = t.TypeOf<
    typeof ImmutableGetAssetsParamsCodec
  >;
  export type ImmutableGetAssetsParamsTS = t.OutputOf<
    typeof ImmutableGetAssetsParamsCodec
  >;

  export const ImmutableGetCollectionsParamsCodec =
    ImmutablePaginatedParamsCodec;
  export type ImmutableGetCollectionsParams = t.TypeOf<
    typeof ImmutableGetCollectionsParamsCodec
  >;
  export type ImmutableGetCollectionsParamsTS = t.OutputOf<
    typeof ImmutableGetCollectionsParamsCodec
  >;

  export const ImmutableGetApplicationsParamsCodec =
    ImmutablePaginatedParamsCodec;
  export type ImmutableGetApplicationsParams = t.TypeOf<
    typeof ImmutableGetApplicationsParamsCodec
  >;
  export type ImmutableGetApplicationsParamsTS = t.OutputOf<
    typeof ImmutableGetApplicationsParamsCodec
  >;

  export const ImmutableGetTransactionParamsCodec = t.intersection([
    ImmutablePaginatedParamsCodec,
    t.partial({
      user: EthAddress,
      receiver: EthAddress,
      status: ImmutableTransactionStatusCodec,
      rollup_status: ImmutableRollupStatusCodec,
      min_timestamp: t.string,
      max_timestamp: t.string,
      token_type: TokenTypeCodec,
      token_id: t.string,
      token_address: EthAddress,
      min_quantity: PositiveIntegerStringC,
      max_quantity: PositiveIntegerStringC,
      metadata: t.string,
    }),
  ]);
  export type ImmutableGetTransactionParams = t.TypeOf<
    typeof ImmutableGetTransactionParamsCodec
  >;
  export type ImmutableGetTransactionParamsTS = t.OutputOf<
    typeof ImmutableGetTransactionParamsCodec
  >;

  export const ImmutableGetDepositsParamsCodec =
    ImmutableGetTransactionParamsCodec;
  export type ImmutableGetDepositsParams = t.TypeOf<
    typeof ImmutableGetDepositsParamsCodec
  >;
  export type ImmutableGetDepositsParamsTS = t.OutputOf<
    typeof ImmutableGetDepositsParamsCodec
  >;

  export const ImmutableGetWithdrawalsParamsCodec = t.intersection([
    ImmutableGetTransactionParamsCodec,
    t.partial({
      withdrawn_to_wallet: t.boolean,
    }),
  ]);
  export type ImmutableGetWithdrawalsParams = t.TypeOf<
    typeof ImmutableGetWithdrawalsParamsCodec
  >;
  export type ImmutableGetWithdrawalsParamsTS = t.OutputOf<
    typeof ImmutableGetWithdrawalsParamsCodec
  >;

  export const ImmutableGetTransfersParamsCodec =
    ImmutableGetTransactionParamsCodec;
  export type ImmutableGetTransfersParams = t.TypeOf<
    typeof ImmutableGetTransfersParamsCodec
  >;
  export type ImmutableGetTransfersParamsTS = t.OutputOf<
    typeof ImmutableGetTransfersParamsCodec
  >;

  export const ImmutableGetBurnsParamsCodec =
    ImmutableGetTransactionParamsCodec;
  export type ImmutableGetBurnsParams = t.TypeOf<
    typeof ImmutableGetBurnsParamsCodec
  >;
  export type ImmutableGetBurnsParamsTS = t.OutputOf<
    typeof ImmutableGetBurnsParamsCodec
  >;

  export const ImmutableGetExchangesParamsCodec = t.intersection([
    ImmutablePaginatedParamsCodec,
    t.partial({
      wallet_address: EthAddress,
      status: t.string,
      exchange: t.string,
      transfer_id: t.string,
      id: t.number,
    }),
  ]);
  export type ImmutableGetExchangesParams = t.TypeOf<
    typeof ImmutableGetExchangesParamsCodec
  >;
  export type ImmutableGetExchangesParamsTS = t.OutputOf<
    typeof ImmutableGetExchangesParamsCodec
  >;

  export const ImmutableGetTradesParamsCodec = t.intersection([
    ImmutablePaginatedParamsCodec,
    t.partial({
      status: ImmutableTransactionStatusCodec,
      min_timestamp: t.string,
      max_timestamp: t.string,
    }),
    t.partial({
      party_a_token_type: TokenTypeCodec,
      party_a_token_address: EthAddress,
      party_a_token_id: t.string,
      party_b_token_type: TokenTypeCodec,
      party_b_token_address: EthAddress,
      party_b_token_id: t.string,
    }),
  ]);
  export type ImmutableGetTradesParams = t.TypeOf<
    typeof ImmutableGetTradesParamsCodec
  >;
  export type ImmutableGetTradesParamsTS = t.OutputOf<
    typeof ImmutableGetTradesParamsCodec
  >;

  export const ImmutableGetAssetParamsCodec = t.intersection([
    t.type({
      address: EthAddress,
      id: t.string,
    }),
    t.partial({ include_fees: t.boolean }),
  ]);
  export type ImmutableGetAssetParams = t.TypeOf<
    typeof ImmutableGetAssetParamsCodec
  >;
  export type ImmutableGetAssetParamsTS = t.OutputOf<
    typeof ImmutableGetAssetParamsCodec
  >;

  export const ImmutableGetCollectionParamsCodec = t.type({
    address: HexadecimalString,
  });
  export type ImmutableGetCollectionParams = t.TypeOf<
    typeof ImmutableGetCollectionParamsCodec
  >;
  export type ImmutableGetCollectionParamsTS = t.OutputOf<
    typeof ImmutableGetCollectionParamsCodec
  >;

  export const ImmutableGetDepositParamsCodec = t.type({
    id: t.Int,
  });
  export type ImmutableGetDepositParams = t.TypeOf<
    typeof ImmutableGetDepositParamsCodec
  >;
  export type ImmutableGetDepositParamsTS = t.OutputOf<
    typeof ImmutableGetDepositParamsCodec
  >;

  export const ImmutableGetTransferParamsCodec = t.type({
    id: t.Int,
  });
  export type ImmutableGetTransferParams = t.TypeOf<
    typeof ImmutableGetTransferParamsCodec
  >;
  export type ImmutableGetTransferParamsTS = t.OutputOf<
    typeof ImmutableGetTransferParamsCodec
  >;

  export const ImmutableGetBurnParamsCodec = ImmutableGetTransferParamsCodec;
  export type ImmutableGetBurnParams = t.TypeOf<
    typeof ImmutableGetBurnParamsCodec
  >;
  export type ImmutableGetBurnParamsTS = t.OutputOf<
    typeof ImmutableGetBurnParamsCodec
  >;

  export const ImmutableGetWithdrawalParamsCodec = t.type({
    id: t.Int,
  });
  export type ImmutableGetWithdrawalParams = t.TypeOf<
    typeof ImmutableGetWithdrawalParamsCodec
  >;
  export type ImmutableGetWithdrawalParamsTS = t.OutputOf<
    typeof ImmutableGetWithdrawalParamsCodec
  >;

  export const ImmutableGetTradeParamsCodec = t.type({
    id: t.Int,
  });
  export type ImmutableGetTradeParams = t.TypeOf<
    typeof ImmutableGetTradeParamsCodec
  >;
  export type ImmutableGetTradeParamsTS = t.OutputOf<
    typeof ImmutableGetTradeParamsCodec
  >;

  export const ImmutableStarkApproveNFTParamsCodec = t.type({
    tokenAddress: EthAddress,
    tokenId: t.string,
  });
  export type ImmutableStarkApproveNFTParams = t.TypeOf<
    typeof ImmutableStarkApproveNFTParamsCodec
  >;
  export type ImmutableStarkApproveNFTParamsTS = t.OutputOf<
    typeof ImmutableStarkApproveNFTParamsCodec
  >;

  export const ImmutableStarkApproveERC20ParamsCodec = t.type({
    tokenAddress: EthAddress,
    amount: PositiveBigNumber,
  });
  export type ImmutableStarkApproveERC20Params = t.TypeOf<
    typeof ImmutableStarkApproveERC20ParamsCodec
  >;
  export type ImmutableStarkApproveERC20ParamsTS = t.OutputOf<
    typeof ImmutableStarkApproveERC20ParamsCodec
  >;

  export const ImmutableMintNFTParamsCodec = t.type({
    tokenAddress: EthAddress,
  });
  export type ImmutableMintNFTParams = t.TypeOf<
    typeof ImmutableMintNFTParamsCodec
  >;
  export type ImmutableMintNFTParamsTS = t.OutputOf<
    typeof ImmutableMintNFTParamsCodec
  >;

  export const ImmutablePrepareWithdrawalParamsCodec = t.type({
    user: EthAddress,
    token: TokenCodec,
    quantity: PositiveBigNumber,
  });
  export type ImmutablePrepareWithdrawalParams = t.TypeOf<
    typeof ImmutablePrepareWithdrawalParamsCodec
  >;
  export type ImmutablePrepareWithdrawalParamsTS = t.OutputOf<
    typeof ImmutablePrepareWithdrawalParamsCodec
  >;

  export const ImmutableCompleteWithdrawalParamsCodec = t.type({
    starkPublicKey: HexadecimalString,
    token: TokenCodec,
  });
  export type ImmutableCompleteWithdrawalParams = t.TypeOf<
    typeof ImmutableCompleteWithdrawalParamsCodec
  >;
  export type ImmutableCompleteWithdrawalParamsTS = t.OutputOf<
    typeof ImmutableCompleteWithdrawalParamsCodec
  >;

  export const ImmutableGetMintableTokenParamsCodec = t.type({
    tokenAddress: t.string,
    tokenId: t.string,
  });
  export type ImmutableGetMintableTokenParams = t.TypeOf<
    typeof ImmutableGetMintableTokenParamsCodec
  >;
  export type ImmutableGetMintableTokenParamsTS = t.OutputOf<
    typeof ImmutableGetMintableTokenParamsCodec
  >;
}

export namespace StarkMethodParams {
  export const StarkAccountParamsCodec = t.type({
    layer: t.string,
    application: t.string,
    index: PositiveIntegerStringC,
  });
  export type StarkAccountParams = t.TypeOf<typeof StarkAccountParamsCodec>;

  export const StarkRegisterParamsCodec = t.type({
    contractAddress: EthAddress,
    etherKey: EthAddress,
    starkPublicKey: HexadecimalString,
    operatorSignature: t.string,
  });
  export type StarkRegisterParams = t.TypeOf<typeof StarkRegisterParamsCodec>;
  export type StarkRegisterParamsTS = t.OutputOf<
    typeof StarkRegisterParamsCodec
  >;

  export const StarkDepositParamsCodec = t.type({
    contractAddress: EthAddress,
    starkPublicKey: HexadecimalString,
    quantity: PositiveBigNumber,
    quantizedAmount: PositiveBigNumber, // only used for ERC20s
    assetId: HexadecimalString,
    token: TokenCodec,
    vaultId: PositiveIntegerStringC,
  });
  export type StarkDepositParams = t.TypeOf<typeof StarkDepositParamsCodec>;
  export type StarkDepositParamsTS = t.OutputOf<typeof StarkDepositParamsCodec>;

  export const StarkDepositCancelParamsCodec = t.type({
    contractAddress: EthAddress,
    starkPublicKey: HexadecimalString,
    token: TokenCodec,
    vaultId: PositiveIntegerStringC,
  });
  export type StarkDepositCancelParams = t.TypeOf<
    typeof StarkDepositCancelParamsCodec
  >;

  export const StarkDepositReclaimParamsCodec = t.type({
    contractAddress: EthAddress,
    starkPublicKey: HexadecimalString,
    token: TokenCodec,
    vaultId: PositiveIntegerStringC,
  });
  export type StarkDepositReclaimParams = t.TypeOf<
    typeof StarkDepositReclaimParamsCodec
  >;

  export const StarkTransferParamsCodec = t.type({
    from: TransferParamsCodec,
    to: TransferParamsCodec,
    token: TokenCodec,
    quantity: PositiveBigNumber,
    nonce: PositiveIntegerStringC,
    expirationTimestamp: PositiveIntegerStringC,
  });
  export type StarkTransferParams = t.TypeOf<typeof StarkTransferParamsCodec>;

  export const StarkCreateOrderParamsCodec = t.type({
    starkPublicKey: HexadecimalString,
    sell: OrderParamsCodec,
    buy: OrderParamsCodec,
    nonce: PositiveIntegerStringC,
    expirationTimestamp: PositiveIntegerStringC,
  });
  export type StarkCreateOrderParams = t.TypeOf<
    typeof StarkCreateOrderParamsCodec
  >;

  export const StarkWithdrawalParamsCodec = t.type({
    contractAddress: EthAddress,
    starkPublicKey: HexadecimalString,
    token: TokenCodec,
  });
  export type StarkWithdrawalParams = t.TypeOf<
    typeof StarkWithdrawalParamsCodec
  >;

  export const StarkFullWithdrawalParamsCodec = t.type({
    contractAddress: EthAddress,
    starkPublicKey: HexadecimalString,
    vaultId: PositiveIntegerStringC,
  });
  export type StarkFullWithdrawalParams = t.TypeOf<
    typeof StarkFullWithdrawalParamsCodec
  >;

  export const StarkFreezeParamsCodec = t.type({
    contractAddress: EthAddress,
    starkPublicKey: HexadecimalString,
    vaultId: PositiveIntegerStringC,
  });
  export type StarkFreezeParams = t.TypeOf<typeof StarkFreezeParamsCodec>;

  export const StarkVerifyEscapeParamsCodec = t.type({
    contractAddress: EthAddress,
    starkPublicKey: HexadecimalString,
    proof: t.array(t.string),
  });
  export type StarkVerifyEscapeParams = t.TypeOf<
    typeof StarkVerifyEscapeParamsCodec
  >;

  export const StarkEscapeParamsCodec = t.type({
    contractAddress: EthAddress,
    starkPublicKey: HexadecimalString,
    vaultId: PositiveIntegerStringC,
    token: TokenCodec,
    quantity: PositiveBigNumber,
  });
  export type StarkEscapeParams = t.TypeOf<typeof StarkEscapeParamsCodec>;

  export const StarkRegisterTokenParamsCodec = t.type({
    contractAddress: EthAddress,
    token: TokenCodec,
  });
  export type StarkRegisterTokenParams = t.TypeOf<
    typeof StarkRegisterTokenParamsCodec
  >;

  export const StarkGetEthKeyParamsCodec = t.type({
    contractAddress: EthAddress,
    user: EthAddress,
  });
  export type StarkGetEthKeyParams = t.TypeOf<typeof StarkGetEthKeyParamsCodec>;

  export const StarkGetDepositBalanceParamsCodec = t.type({
    contractAddress: EthAddress,
    starkPublicKey: HexadecimalString,
    token: TokenCodec,
    vaultId: PositiveIntegerStringC,
  });
  export type StarkGetDepositBalanceParams = t.TypeOf<
    typeof StarkGetDepositBalanceParamsCodec
  >;

  export const StarkGetWithdrawalBalanceParamsCodec = t.type({
    contractAddress: EthAddress,
    starkPublicKey: HexadecimalString,
    token: TokenCodec,
  });
  export type StarkGetWithdrawalBalanceParams = t.TypeOf<
    typeof StarkGetWithdrawalBalanceParamsCodec
  >;

  export const StarkMintNFTParamsCodec = t.type({
    tokenAddress: EthAddress,
    to: EthAddress,
    tokenId: t.string,
    tokenUri: t.string,
  });
  export type StarkMintNFTParams = t.TypeOf<typeof StarkMintNFTParamsCodec>;

  export const StarkApproveNFTParamsCodec = t.type({
    contractAddress: EthAddress,
    tokenAddress: EthAddress,
    tokenId: t.string,
  });
  export type StarkApproveNFTParams = t.TypeOf<
    typeof StarkApproveNFTParamsCodec
  >;
  export type StarkApproveNFTParamsTS = t.OutputOf<
    typeof StarkApproveNFTParamsCodec
  >;

  export const StarkApproveERC20ParamsCodec = t.type({
    contractAddress: EthAddress,
    amount: PositiveBigNumber,
    tokenAddress: EthAddress,
  });
  export type StarkApproveERC20Params = t.TypeOf<
    typeof StarkApproveERC20ParamsCodec
  >;
  export type StarkApproveERC20ParamsTS = t.OutputOf<
    typeof StarkApproveNFTParamsCodec
  >;

  export const StarkSetupNFTParamsCodec = t.type({
    tokenAddress: EthAddress,
    contractAddress: EthAddress,
    to: EthAddress,
    tokenId: t.string,
    tokenUri: t.string,
  });
  export type StarkSetupNFTParams = t.TypeOf<typeof StarkSetupNFTParamsCodec>;
}

export namespace RegistrationMethodParams {
  export const RegisterAndDepositParamsCodec = t.type({
    registrationContractAddress: EthAddress,
    starkPublicKey: HexadecimalString,
    quantity: PositiveBigNumber,
    quantizedAmount: PositiveBigNumber, // only used for ERC20s
    assetId: HexadecimalString,
    token: TokenCodec,
    vaultId: PositiveIntegerStringC,
    etherKey: EthAddress,
    operatorSignature: t.string,
  });
  export type RegisterAndDepositParams = t.TypeOf<
    typeof RegisterAndDepositParamsCodec
  >;
  export type RegisterAndDepositParamsTS = t.OutputOf<
    typeof RegisterAndDepositParamsCodec
  >;

  export const RegisterAndWithdrawParamsCodec = t.type({
    registrationContractAddress: EthAddress,
    starkPublicKey: HexadecimalString,
    token: TokenCodec,
    etherKey: EthAddress,
    operatorSignature: t.string,
  });
  export type RegisterAndWithdrawParams = t.TypeOf<
    typeof RegisterAndWithdrawParamsCodec
  >;
  export type RegisterAndWithdrawParamsTS = t.OutputOf<
    typeof RegisterAndWithdrawParamsCodec
  >;
}
export namespace ImmutableMethodResults {
  export const ImmutableSignResultCodec = t.type({
    ethSignature: t.string,
  });
  export type ImmutableSignResult = t.TypeOf<typeof ImmutableSignResultCodec>;
  export const ImmutableRegisterResultCodec = t.type({
    tx_hash: t.string,
  });
  export type ImmutableRegisterResult = t.TypeOf<
    typeof ImmutableRegisterResultCodec
  >;

  export const ImmutableGetUserResultCodec = t.type({
    accounts: t.array(HexadecimalString),
  });

  export type ImmutableGetUserResult = t.TypeOf<
    typeof ImmutableGetUserResultCodec
  >;
  export type ImmutableGetUserResultTS = t.OutputOf<
    typeof ImmutableGetUserResultCodec
  >;

  export const ImmutableGetSignableDepositResultCodec = t.type({
    stark_key: HexadecimalString,
    vault_id: t.Int,
    amount: PositiveBigNumber,
    asset_id: HexadecimalString,
    nonce: t.Int,
  });
  export type ImmutableGetSignableDepositResult = t.TypeOf<
    typeof ImmutableGetSignableDepositResultCodec
  >;

  export const ImmutableGetSignableRegistrationResultCodec = t.type({
    operator_signature: t.string,
  });
  export type ImmutableGetSignableRegistrationResult = t.TypeOf<
    typeof ImmutableGetSignableRegistrationResultCodec
  >;

  export const ImmutableDepositResultCodec = t.type({
    deposit_id: t.Int,
    status: t.string,
    time: t.Int,
  });
  export type ImmutableDepositResult = t.TypeOf<
    typeof ImmutableDepositResultCodec
  >;

  export const ImmutableGetSignableWithdrawalResultCodec = t.type({
    stark_key: HexadecimalString,
    vault_id: t.Int,
    amount: PositiveBigNumber,
    asset_id: HexadecimalString,
    nonce: t.Int,
    signable_message: t.string,
  });
  export type ImmutableGetSignableWithdrawalResult = t.TypeOf<
    typeof ImmutableGetSignableWithdrawalResultCodec
  >;

  export const ImmutableWithdrawalResultCodec = t.type({
    withdrawal_id: t.Int,
    status: t.string,
    time: t.Int,
  });
  export type ImmutableWithdrawalResult = t.TypeOf<
    typeof ImmutableWithdrawalResultCodec
  >;

  export const ImmutableGetSignableTransferResultCodec = t.type({
    sender_stark_key: HexadecimalString,
    sender_vault_id: t.Int,
    receiver_stark_key: HexadecimalString,
    receiver_vault_id: t.Int,
    amount: PositiveBigNumber,
    asset_id: HexadecimalString,
    expiration_timestamp: t.Int,
    nonce: t.Int,
    signable_message: t.string,
  });
  export type ImmutableGetSignableTransferResult = t.TypeOf<
    typeof ImmutableGetSignableTransferResultCodec
  >;

  export const ImmutableSignableTransferV2Codec = t.type({
    nonce: t.Int,
    sender_vault_id: t.Int,
    receiver_vault_id: t.Int,
    receiver_stark_key: HexadecimalString,
    amount: PositiveBigNumber, // t.string,
    asset_id: HexadecimalString,
    expiration_timestamp: t.Int,
    token: TokenCodec,
  });
  export type ImmutableSignableTransferV2 = t.TypeOf<
    typeof ImmutableSignableTransferV2Codec
  >;

  export const ImmutableGetSignableTransferV2ResultCodec = t.type({
    sender_stark_key: HexadecimalString,
    signable_responses: t.array(ImmutableSignableTransferV2Codec),
    signable_message: t.string,
  });
  export type ImmutableGetSignableTransferV2Result = t.TypeOf<
    typeof ImmutableGetSignableTransferV2ResultCodec
  >;
  export type ImmutableGetSignableTransferV2ResultTS = t.OutputOf<
    typeof ImmutableGetSignableTransferV2ResultCodec
  >;

  export const ImmutableTransferResultCodec = t.type({
    transfer_id: t.Int,
    status: t.string,
    time: t.Int,
    sent_signature: t.string,
  });
  export type ImmutableTransferResult = t.TypeOf<
    typeof ImmutableTransferResultCodec
  >;
  export type ImmutableBurnResult = t.TypeOf<
    typeof ImmutableTransferResultCodec
  >;

  export const ImmutableTransferV2ResultCodec = t.type({
    transfer_ids: t.array(t.Int),
  });
  export type ImmutableTransferV2Result = t.TypeOf<
    typeof ImmutableTransferV2ResultCodec
  >;
  export type ImmutableTransferV2ResultTS = t.OutputOf<
    typeof ImmutableTransferV2ResultCodec
  >;

  export const ImmutableOffchainMintV2ResultCodec = t.type({
    token_id: t.string,
    contract_address: t.string,
    tx_id: t.Int,
  });
  export type ImmutableOffchainMintV2Result = t.TypeOf<
    typeof ImmutableOffchainMintV2ResultCodec
  >;
  export type ImmutableOffchainMintV2ResultTS = t.OutputOf<
    typeof ImmutableOffchainMintV2ResultCodec
  >;

  export const ImmutableOffchainMintV2ResultsCodec = t.type({
    results: t.array(ImmutableOffchainMintV2ResultCodec),
  });
  export type ImmutableOffchainMintV2Results = t.TypeOf<
    typeof ImmutableOffchainMintV2ResultsCodec
  >;
  export type ImmutableOffchainMintV2ResultsTS = t.OutputOf<
    typeof ImmutableOffchainMintV2ResultsCodec
  >;

  export const ImmutableOffchainMintResultCodec = t.type({
    token_id: t.string,
    client_token_id: t.string,
    tx_id: t.Int,
  });
  export type ImmutableOffchainMintResult = t.TypeOf<
    typeof ImmutableOffchainMintResultCodec
  >;
  export type ImmutableOffchainMintResultTS = t.OutputOf<
    typeof ImmutableOffchainMintResultCodec
  >;

  export const ImmutableOffchainMintResultsCodec = t.type({
    results: t.array(ImmutableOffchainMintResultCodec),
  });
  export type ImmutableOffchainMintResults = t.TypeOf<
    typeof ImmutableOffchainMintResultsCodec
  >;
  export type ImmutableOffchainMintResultsTS = t.OutputOf<
    typeof ImmutableOffchainMintResultsCodec
  >;

  export const ImmutableMintFeeResultCodec = t.type({
    type: fromEnum<ImmutableFeeType.royalty>(
      'ImmutableFeeType',
      ImmutableFeeType,
    ),
    percentage: t.number,
    address: EthAddress,
  });

  export const ImmutableGetSignableOrderResultCodec = t.intersection([
    t.type({
      stark_key: HexadecimalString,
      nonce: t.Int,
      expiration_timestamp: t.Int,
      amount_sell: PositiveBigNumber,
      asset_id_sell: HexadecimalString,
      vault_id_sell: t.Int,
      amount_buy: PositiveBigNumber,
      asset_id_buy: HexadecimalString,
      vault_id_buy: t.Int,
      signable_message: t.string,
    }),
    t.partial({ fee_info: ImmutableFeeInfoCodec }),
  ]);
  export type ImmutableGetSignableOrderResult = t.TypeOf<
    typeof ImmutableGetSignableOrderResultCodec
  >;

  export const ImmutableGetSignableCancelOrderResultCodec = t.type({
    signable_message: t.string,
  });
  export type ImmutableGetSignableCancelOrderResult = t.TypeOf<
    typeof ImmutableGetSignableOrderResultCodec
  >;

  export const ImmutableCreateOrderResultCodec = t.type({
    order_id: t.Int,
    status: t.string,
  });
  export type ImmutableCreateOrderResult = t.TypeOf<
    typeof ImmutableCreateOrderResultCodec
  >;

  export const ImmutableCancelOrderResultCodec = t.type({
    order_id: t.Int,
    status: t.string,
  });
  export type ImmutableCancelOrderResult = t.TypeOf<
    typeof ImmutableCancelOrderResultCodec
  >;

  export const ImmutableCollectionDetailsCodec = t.type({
    name: t.string,
    icon_url: t.union([t.null, t.string]),
  });

  export type ImmutableCollectionDetails = t.TypeOf<
    typeof ImmutableCollectionDetailsCodec
  >;

  export const ImmutableCollectionCodec = t.type({
    name: t.string,
    icon_url: t.union([t.null, t.string]),
    metadata_api_url: t.union([t.null, t.string]),
  });

  export type ImmutableCollection = t.TypeOf<typeof ImmutableCollectionCodec>;

  export const ImmutablePropertiesCodec = t.type({
    name: t.union([t.null, t.string]),
    image_url: t.union([t.null, t.string]),
    collection: ImmutableCollectionDetailsCodec,
  });
  export type ImmutableProperties = t.TypeOf<typeof ImmutablePropertiesCodec>;

  export const ImmutableETHTokenCodec = t.interface({
    type: ETHTokenTypeT,
    data: t.type({
      quantity: PositiveBigNumber,
      // @TODO: Cannot add this type because the 'decimals' incorrectly returned
      // by the API can be '0' (https://immutable.atlassian.net/browse/IMX-3556).
      // decimals: PositiveIntegerC,
      decimals: t.Int,
    }),
  });
  export type ImmutableETHToken = t.TypeOf<typeof ImmutableETHTokenCodec>;
  export type ImmutableETHTokenTS = t.OutputOf<typeof ImmutableETHTokenCodec>;

  export const ImmutableERC20TokenCodec = t.interface({
    type: ERC20TokenTypeT,
    data: t.type({
      token_address: EthAddress,
      quantity: PositiveBigNumber,
      // @TODO: Cannot add this type because the 'decimals' incorrectly returned
      // by the API can be '0' (https://immutable.atlassian.net/browse/IMX-3556).
      // decimals: PositiveIntegerC,
      decimals: t.Int,
    }),
  });

  export type ImmutableERC20Token = t.TypeOf<typeof ImmutableERC20TokenCodec>;
  export type ImmutableERC20TokenTS = t.OutputOf<
    typeof ImmutableERC20TokenCodec
  >;

  export const ImmutableERC721TokenCodec = t.type({
    type: ERC721TokenTypeT,
    data: t.intersection([
      t.type({
        token_id: t.string,
        token_address: EthAddress,
        quantity: PositiveBigNumber,
      }),
      t.partial({
        properties: ImmutablePropertiesCodec,
      }),
    ]),
  });

  export type ImmutableERC721Token = t.TypeOf<typeof ImmutableERC721TokenCodec>;
  export type ImmutableERC721TokenTS = t.OutputOf<
    typeof ImmutableERC721TokenCodec
  >;

  export const ImmutableTokenCodec = t.union([
    ImmutableETHTokenCodec,
    ImmutableERC20TokenCodec,
    ImmutableERC721TokenCodec,
  ]);
  export type ImmutableToken = t.TypeOf<typeof ImmutableTokenCodec>;
  export type ImmutableTokenTS = t.OutputOf<typeof ImmutableTokenCodec>;

  export const ImmutablePaginatedResultCodec = t.intersection([
    t.type({ cursor: t.string }),
    t.partial({ remaining: t.Int }),
  ]);
  export type ImmutablePaginatedResult = t.TypeOf<
    typeof ImmutablePaginatedResultCodec
  >;
  export type ImmutablePaginatedResultTS = t.OutputOf<
    typeof ImmutablePaginatedResultCodec
  >;

  const FeeData = t.intersection([
    t.partial({ contract_address: t.string }), // `json:"contract_address,omitempty" db:"token_address"`
    t.type({ decimals: t.number }),
  ]);

  const FeeToken = t.type({
    type: t.string,
    data: FeeData,
  });

  export const ImmutableOrderFeeInfo = t.type({
    type: ImmutableFeeTypeCodec,
    address: t.string,
    token: FeeToken,
    amount: t.string,
  });

  export const ImmutableGetOrderResultCodec = t.intersection([
    t.type({
      order_id: t.Int,
      status: ImmutableOrderStatusCodec,
      user: EthAddress,
      sell: ImmutableTokenCodec,
      buy: ImmutableTokenCodec,
      amount_sold: t.union([PositiveBigNumber, t.null]),
      expiration_timestamp: DateFromISOString,
      timestamp: DateFromISOString,
    }),
    t.partial({ fees: t.union([t.array(ImmutableOrderFeeInfo), t.null]) }),
  ]);

  export type ImmutableGetOrderResult = t.TypeOf<
    typeof ImmutableGetOrderResultCodec
  >;
  export type ImmutableGetOrderResultTS = t.OutputOf<
    typeof ImmutableGetOrderResultCodec
  >;

  export const ImmutableGetOrdersResultCodec = t.intersection([
    t.type({
      result: t.array(ImmutableGetOrderResultCodec),
    }),
    ImmutablePaginatedResultCodec,
  ]);
  export type ImmutableGetOrdersResult = t.TypeOf<
    typeof ImmutableGetOrdersResultCodec
  >;

  export const ImmutableOrderMakerTakerFeesTypeCodec = t.intersection([
    t.type({
      quantity_with_fees: PositiveBigNumber,
      token_type: TokenTypeCodec,
      decimals: t.Int,
      symbol: t.string,
    }),
    t.partial({
      token_address: t.string,
      fees: t.union([t.array(ImmutableOrderFeeInfo), t.null]),
    }),
  ]);

  export type ImmutableOrderMakerTakerFeesType = t.TypeOf<
    typeof ImmutableOrderMakerTakerFeesTypeCodec
  >;
  export type ImmutableOrderMakerTakerFeesTypeTS = t.OutputOf<
    typeof ImmutableOrderMakerTakerFeesTypeCodec
  >;

  export const ImmutableGetOrderV3ResultCodec = t.intersection([
    t.type({
      order_id: t.Int,
      status: ImmutableOrderStatusCodec,
      user: EthAddress,
      sell: ImmutableTokenCodec,
      buy: ImmutableTokenCodec,
      amount_sold: t.union([PositiveBigNumber, t.null]),
      expiration_timestamp: DateFromISOString,
      timestamp: DateFromISOString,
      updated_timestamp: DateFromISOString,
      maker_taker_type: ImmutableOrderMakerTakerTypeCodec,
      maker_fees: ImmutableOrderMakerTakerFeesTypeCodec,
      taker_fees: ImmutableOrderMakerTakerFeesTypeCodec,
    }),
    t.partial({ fees: t.union([t.array(ImmutableOrderFeeInfo), t.null]) }),
  ]);

  export type ImmutableGetOrderV3Result = t.TypeOf<
    typeof ImmutableGetOrderV3ResultCodec
  >;
  export type ImmutableGetOrderV3ResultTS = t.OutputOf<
    typeof ImmutableGetOrderV3ResultCodec
  >;

  export const ImmutableGetOrdersV3ResultCodec = t.intersection([
    t.type({
      result: t.array(ImmutableGetOrderV3ResultCodec),
    }),
    ImmutablePaginatedResultCodec,
  ]);
  export type ImmutableGetOrdersV3Result = t.TypeOf<
    typeof ImmutableGetOrdersV3ResultCodec
  >;

  export const ImmutableApplicationCodec = t.type({
    id: t.string,
    name: t.string,
    created_at: t.string,
  });

  export type ImmutableApplication = t.TypeOf<typeof ImmutableApplicationCodec>;

  export const ImmutableGetApplicationsResultCodec = t.intersection([
    t.type({
      result: t.array(ImmutableApplicationCodec),
    }),
    ImmutablePaginatedResultCodec,
  ]);
  export type ImmutableGetApplicationsResult = t.TypeOf<
    typeof ImmutableGetApplicationsResultCodec
  >;

  export const ImmutableSellOrderCodec = t.intersection([
    t.type({
      order_id: t.number,
      user: t.string,
      buy_quantity: PositiveBigNumber,
    }),
    t.partial({
      buy_decimals: t.number,
      status: ImmutableOrderStatusCodec,
      contract_address: t.string,
    }),
  ]);

  export type ImmutableSellOrder = t.TypeOf<typeof ImmutableSellOrderCodec>;
  export type ImmutableSellOrderTS = t.OutputOf<typeof ImmutableSellOrderCodec>;

  export const ImmutableBuyOrderCodec = t.intersection([
    t.type({
      order_id: t.number,
      user: t.string,
      sell_quantity: PositiveBigNumber,
    }),
    t.partial({
      sell_decimals: t.number,
      status: ImmutableOrderStatusCodec,
      contract_address: t.string,
    }),
  ]);

  export type ImmutableBuyOrder = t.TypeOf<typeof ImmutableBuyOrderCodec>;
  export type ImmutableBuyOrderTS = t.OutputOf<typeof ImmutableBuyOrderCodec>;

  export const ImmutableAssetCodec = t.intersection([
    t.type({
      token_address: HexadecimalString,
      token_id: t.string,
      user: t.union([t.null, EthAddress]),
      status: ImmutableAssetStatusCodec,
      uri: t.union([t.null, t.string]),
      name: t.union([t.null, t.string]),
      description: t.union([t.null, t.string]),
      image_url: t.union([t.null, t.string]),
      metadata: t.unknown,
      collection: ImmutableCollectionDetailsCodec,
    }),
    t.partial({
      orders: t.partial({
        sell_orders: t.union([t.null, t.array(ImmutableSellOrderCodec)]),
        buy_orders: t.union([t.null, t.array(ImmutableBuyOrderCodec)]),
      }),
      fees: t.array(ImmutableMintFeeResultCodec),
    }),
  ]);
  export type ImmutableAsset = t.TypeOf<typeof ImmutableAssetCodec>;
  export type ImmutableAssetTS = t.OutputOf<typeof ImmutableAssetCodec>;

  export const ImmutableGetAssetsResultCodec = t.intersection([
    t.type({
      result: t.array(ImmutableAssetCodec),
    }),
    ImmutablePaginatedResultCodec,
  ]);
  export type ImmutableGetAssetsResult = t.TypeOf<
    typeof ImmutableGetAssetsResultCodec
  >;

  export const ImmutableGetCollectionsResultCodec = t.intersection([
    t.type({
      result: t.array(ImmutableCollectionCodec),
    }),
    ImmutablePaginatedResultCodec,
  ]);
  export type ImmutableGetCollectionsResult = t.TypeOf<
    typeof ImmutableGetCollectionsResultCodec
  >;

  export const ImmutableCreateTradeResultCodec = t.intersection([
    t.type({
      trade_id: t.Int,
      status: t.string,
    }),
    t.partial({
      request_id: t.string,
    }),
  ]);
  export type ImmutableCreateTradeResult = t.TypeOf<
    typeof ImmutableCreateTradeResultCodec
  >;

  export const ImmutableDepositCodec = t.type({
    transaction_id: t.Int,
    status: ImmutableTransactionStatusCodec,
    user: EthAddress,
    token: ImmutableTokenCodec,
    timestamp: DateFromISOString,
  });
  export type ImmutableDeposit = t.TypeOf<typeof ImmutableDepositCodec>;

  export const ImmutableGetDepositsResultCodec = t.intersection([
    t.type({
      result: t.array(ImmutableDepositCodec),
    }),
    ImmutablePaginatedResultCodec,
  ]);
  export type ImmutableGetDepositsResult = t.TypeOf<
    typeof ImmutableGetDepositsResultCodec
  >;

  export const ImmutableWithdrawalCodec = t.type({
    transaction_id: t.Int,
    sender: EthAddress,
    status: ImmutableTransactionStatusCodec,
    timestamp: DateFromISOString,
    rollup_status: ImmutableRollupStatusCodec,
    withdrawn_to_wallet: t.boolean,
    token: ImmutableTokenCodec,
  });

  export type ImmutableWithdrawal = t.TypeOf<typeof ImmutableWithdrawalCodec>;
  export type ImmutableWithdrawalTS = t.OutputOf<
    typeof ImmutableWithdrawalCodec
  >;

  export const ImmutableGetWithdrawalsResultCodec = t.intersection([
    t.type({
      result: t.array(ImmutableWithdrawalCodec),
    }),
    ImmutablePaginatedResultCodec,
  ]);
  export type ImmutableGetWithdrawalsResult = t.TypeOf<
    typeof ImmutableGetWithdrawalsResultCodec
  >;

  export const ImmutableTransferCodec = t.type({
    transaction_id: t.Int,
    status: ImmutableTransactionStatusCodec,
    user: EthAddress,
    receiver: EthAddress,
    token: ImmutableTokenCodec,
    timestamp: DateFromISOString,
  });

  export type ImmutableTransfer = t.TypeOf<typeof ImmutableTransferCodec>;

  export const ImmutableGetTransfersResultCodec = t.intersection([
    t.type({
      result: t.array(ImmutableTransferCodec),
    }),
    ImmutablePaginatedResultCodec,
  ]);
  export type ImmutableGetTransfersResult = t.TypeOf<
    typeof ImmutableGetTransfersResultCodec
  >;

  export type ImmutableBurn = t.TypeOf<typeof ImmutableTransferCodec>;

  export const ImmutableGetBurnsResultCodec = t.intersection([
    t.type({
      result: t.array(ImmutableTransferCodec),
    }),
    ImmutablePaginatedResultCodec,
  ]);
  export type ImmutableGetBurnsResult = t.TypeOf<
    typeof ImmutableGetBurnsResultCodec
  >;

  export const ImmutableTradeCodec = t.type({
    a: t.intersection([
      t.type({
        order_id: t.Int,
        token_type: TokenTypeCodec,
        sold: PositiveIntegerStringC,
      }),
      t.partial({
        token_address: EthAddress,
        token_id: t.string,
      }),
    ]),
    b: t.intersection([
      t.type({
        order_id: t.Int,
        token_type: TokenTypeCodec,
        sold: PositiveIntegerStringC,
      }),
      t.partial({
        token_address: EthAddress,
        token_id: t.string,
      }),
    ]),
    status: ImmutableTransactionStatusCodec,
    timestamp: DateFromISOString,
    transaction_id: t.Int,
  });

  export type ImmutableTrade = t.TypeOf<typeof ImmutableTradeCodec>;

  export const ImmutableGetTradesResultCodec = t.intersection([
    t.type({
      result: t.array(ImmutableTradeCodec),
    }),
    ImmutablePaginatedResultCodec,
  ]);

  export type ImmutableGetTradesResult = t.TypeOf<
    typeof ImmutableGetTradesResultCodec
  >;

  // From imx-engine:
  // imx-engine/services/public-api/app/api/types.go
  // GetExchangeResponse
  export const ImmutableGetExchangeResultCodecV1 = t.type({
    id: t.number,
    status: t.string,
    exchange: t.string,
    wallet_address: t.string,
    created_at: DateFromISOString,
    transfer_id: t.string,
    external_id: t.string,
    from_amount: t.number,
    from_currency: t.string,
    to_amount: t.number,
    to_currency: t.string,
    fees_amount: t.number,
  });

  // From imx-exchange:
  // imx-exchange/src/exchange/types.go
  // Exchange
  export const ImmutableExchangeDataCodec = t.type({
    transfer_id: t.string,
    external_id: t.string,
    from_amount: t.number,
    from_currency: t.string,
    to_amount: t.number,
    to_currency: t.string,
    fees_amount: t.number,
    provider_wallet_address: t.union([t.null, t.string]),
  });

  /**
   * NOTE! fiatToCrypto and cryptoToFiat types are deprecated and will be removed
   */
  export const ImmutableExchangeTypeCodec = t.union([
    t.literal('onramp'),
    t.literal('offramp'),
    t.literal('fiatToCrypto'),
    t.literal('cryptoToFiat'),
  ]);

  export const ImmutableGetExchangeResultCodec = t.type({
    id: t.number,
    wallet_address: t.string,
    status: t.string,
    provider: t.string,
    type: ImmutableExchangeTypeCodec,

    data: t.union([ImmutableExchangeDataCodec, t.null]),

    created_at: DateFromISOString,
    updated_at: DateFromISOString,
  });

  export const ImmutableExchangeCurrencyCodec = t.type({
    id: t.number,
    provider: t.string,
    symbol: t.string,
    currency_code: t.string,
    enabled: t.boolean,
  });

  // https://github.com/immutable/imx-exchange/tree/main/aggregate/currency/aggregate/type.go
  export const ImmutableExchangeCurrencyCodecV3 = t.intersection([
    t.type({
      provider: t.string,
      symbol: t.string,
      currency_code: t.string,
    }),
    t.partial({
      limits: t.type({
        min_amount: t.union([t.null, t.number]),
        max_amount: t.union([t.null, t.number]),
      }),
    }),
  ]);

  export type ImmutableGetExchangeResult = t.TypeOf<
    typeof ImmutableGetExchangeResultCodec
  >;

  export type ImmutableGetExchangeResultV1 = t.TypeOf<
    typeof ImmutableGetExchangeResultCodecV1
  >;

  export const ImmutableCreateExchangeResultCodec = t.type({
    id: t.number,
    provider: t.string,
    wallet_address: t.string,
  });

  export type ImmutableCreateExchangeResult = t.TypeOf<
    typeof ImmutableCreateExchangeResultCodec
  >;

  // From imx-engine:
  // imx-engine/services/public-api/app/api/types.go
  // CreateExchangeResponse
  export const ImmutableCreateExchangeResultCodecV1 = t.type({
    id: t.number,
    status: t.string,
    exchange: t.string,
    wallet_address: t.string,
  });

  export type ImmutableCreateExchangeResultV1 = t.TypeOf<
    typeof ImmutableCreateExchangeResultCodecV1
  >;

  export const ImmutableMoonpaySignatureRequestBaseCodec = t.intersection([
    t.type({
      apiKey: t.string,
    }),
    t.partial({
      baseCurrencyCode: t.string,
      colorCode: t.string,
      externalTransactionId: t.string,
      showOnlyCurrencies: t.array(t.string),
      currencyCode: t.string,
    }),
  ]);

  export const ImmutableGetMoonpaySignatureRequestCodec = t.intersection([
    ImmutableMoonpaySignatureRequestBaseCodec,
    t.union([
      t.interface({
        walletAddress: t.string,
      }),
      t.interface({
        walletAddresses: t.record(t.string, t.string),
      }),
    ]),
  ]);

  export const ImmutableGetMoonpaySellSignatureRequestCodec = t.intersection([
    ImmutableMoonpaySignatureRequestBaseCodec,
    t.union([
      t.interface({
        refundWalletAddress: t.string,
      }),
      t.interface({
        refundWalletAddresses: t.record(t.string, t.string),
      }),
    ]),
  ]);

  export const ImmutableGetMoonpayPrimarySellSignatureRequestCodec =
    t.intersection([
      ImmutableMoonpaySignatureRequestBaseCodec,
      t.type({
        externalTransactionId: t.string,
        walletAddress: EthAddress,
        contractAddress: EthAddress,
        tokenId: t.string,
      }),
    ]);

  export const ImmutableGetMoonpaySignatureResultCodec = t.type({
    request: t.string,
    signature: t.string,
  });

  export type ImmutableGetMoonpaySignatureRequest = t.TypeOf<
    typeof ImmutableGetMoonpaySignatureRequestCodec
  >;
  export type ImmutableGetMoonpaySignatureRequestTS = t.OutputOf<
    typeof ImmutableGetMoonpaySignatureRequestCodec
  >;

  export type ImmutableGetMoonpaySellSignatureRequest = t.TypeOf<
    typeof ImmutableGetMoonpaySellSignatureRequestCodec
  >;

  export type ImmutableGetMoonpayPrimarySellSignatureRequest = t.TypeOf<
    typeof ImmutableGetMoonpayPrimarySellSignatureRequestCodec
  >;

  export type ImmutableGetMoonpaySellSignatureRequestTS = t.OutputOf<
    typeof ImmutableGetMoonpaySellSignatureRequestCodec
  >;

  export type ImmutableGetMoonpaySignatureResult = t.TypeOf<
    typeof ImmutableGetMoonpaySignatureResultCodec
  >;

  export const ImmutableGetExchangeHistoryResultCodec = t.intersection([
    t.type({ result: t.array(ImmutableGetExchangeResultCodec) }),
    ImmutablePaginatedResultCodec,
  ]);

  export const ImmutableGetExchangeHistoryResultCodecV1 = t.intersection([
    t.type({ result: t.array(ImmutableGetExchangeResultCodecV1) }),
    ImmutablePaginatedResultCodec,
  ]);

  export type ImmutableGetExchangeHistoryResultV1 = t.TypeOf<
    typeof ImmutableGetExchangeHistoryResultCodecV1
  >;

  export type ImmutableGetExchangeHistoryResult = t.TypeOf<
    typeof ImmutableGetExchangeHistoryResultCodec
  >;

  export const ImmutableGetCurrencyLimitsCodec = t.array(
    t.type({
      symbol: t.string,
      min_amount: t.union([t.null, t.number]),
      max_amount: t.union([t.null, t.number]),
    }),
  );

  export type ImmutableGetCurrencyLimitsResult = t.TypeOf<
    typeof ImmutableGetCurrencyLimitsCodec
  >;

  export const ImmutableGetCurrenciesResultCodec = t.array(
    ImmutableExchangeCurrencyCodec,
  );

  export const ImmutableGetCurrenciesResultCodecV3 = t.array(
    ImmutableExchangeCurrencyCodecV3,
  );

  export type ImmutableGetCurrenciesResult = t.TypeOf<
    typeof ImmutableGetCurrenciesResultCodec
  >;

  export type ImmutableGetCurrenciesResultV3 = t.TypeOf<
    typeof ImmutableGetCurrenciesResultCodecV3
  >;

  export const ImmutableGetTokenResultCodec = t.type({
    token_address: t.union([t.string, EthAddress]),
    image_url: t.union([t.null, t.string]),
    name: t.union([t.null, t.string]),
    decimals: t.string,
    quantum: t.string,
    symbol: t.string,
  });
  export type ImmutableGetTokenResult = t.TypeOf<
    typeof ImmutableGetTokenResultCodec
  >;
  export type ImmutableGetTokenResultTS = t.OutputOf<
    typeof ImmutableGetTokenResultCodec
  >;

  export const ImmutableListTokensResultCodec = t.intersection([
    t.type({ result: t.array(ImmutableGetTokenResultCodec) }),
    ImmutablePaginatedResultCodec,
  ]);
  export type ImmutableListTokensResult = t.TypeOf<
    typeof ImmutableListTokensResultCodec
  >;
  export type ImmutableListTokensResultTS = t.OutputOf<
    typeof ImmutableListTokensResultCodec
  >;

  // TODO: remove once v1 is deprecated, this is for backwards compatibility
  export const ImmutableGetBalancesResultCodec = t.type({
    imx: NonNegativeBigNumber,
    preparing_withdrawal: NonNegativeBigNumber,
    withdrawable: NonNegativeBigNumber,
  });
  export type ImmutableGetBalancesResult = t.TypeOf<
    typeof ImmutableGetBalancesResultCodec
  >;
  export type ImmutableGetBalancesResultTS = t.OutputOf<
    typeof ImmutableGetBalancesResultCodec
  >;

  export type ImmutableBalance = t.TypeOf<typeof ImmutableBalanceCodec>;
  export const ImmutableBalanceCodec = t.type({
    symbol: t.string,
    balance: NonNegativeBigNumber,
    preparing_withdrawal: NonNegativeBigNumber,
    withdrawable: NonNegativeBigNumber,
  });

  export const ImmutableGetBalanceResultCodec = ImmutableBalanceCodec;
  export type ImmutableGetBalanceResult = t.TypeOf<
    typeof ImmutableGetBalanceResultCodec
  >;
  export type ImmutableGetBalanceResultTS = t.OutputOf<
    typeof ImmutableGetBalanceResultCodec
  >;

  export const ImmutableListBalancesResultCodec = t.intersection([
    t.type({ result: t.array(ImmutableBalanceCodec) }),
    ImmutablePaginatedResultCodec,
  ]);
  export type ImmutableListBalancesResult = t.TypeOf<
    typeof ImmutableListBalancesResultCodec
  >;
  export type ImmutableListBalancesResultTS = t.OutputOf<
    typeof ImmutableListBalancesResultCodec
  >;

  export const ImmutableGetMintableTokenResultCodec = t.type({
    token_id: HexadecimalString,
    client_token_id: t.string,
    blueprint: t.string,
  });

  export type ImmutableGetMintableTokenResult = t.TypeOf<
    typeof ImmutableGetMintableTokenResultCodec
  >;
  export type ImmutableGetMintableTokenResultTS = t.OutputOf<
    typeof ImmutableGetMintableTokenResultCodec
  >;
}

export namespace StarkMethodResults {
  export type StarkAccountResult = {
    starkPublicKey: string;
  };
  export type StarkRegisterResult = {
    txhash: string;
  };
  export type StarkDepositResult = {
    txhash: string;
  };
  export type StarkDepositCancelResult = {
    txhash: string;
  };
  export type StarkDepositReclaimResult = {
    txhash: string;
  };
  export type StarkTransferResult = {
    starkSignature: string;
  };
  export type StarkCreateOrderResult = {
    starkSignature: string;
  };
  export type StarkWithdrawalResult = {
    txhash: string;
  };
  export type StarkFullWithdrawalResult = {
    txhash: string;
  };
  export type StarkFreezeResult = {
    txhash: string;
  };
  export type StarkVerifyEscapeResult = {
    txhash: string;
  };
  export type StarkEscapeResult = {
    txhash: string;
  };
  export type StarkRegisterTokenResult = {
    txhash: string;
  };
  export type StarkGetEthKeyResult = {
    ethKey: string;
  };
  export type StarkGetDepositBalanceResult = {
    balance: string;
  };
  export type StarkGetWithdrawalBalanceResult = {
    balance: string;
  };
  export type StarkMintNFTResult = {
    txhash: string;
  };
  export type StarkApproveNFTResult = {
    txhash: string;
  };
  export type StarkSetupNFTResult = {
    txhash: string;
  };
}
