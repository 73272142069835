export enum LocalStorageKeys {
  WALLET_ADDRESS = 'WALLET_ADDRESS',
  STARK_PUBLIC_KEY = 'STARK_PUBLIC_KEY',
  PROVIDER_PREFERENCE = 'PROVIDER_PREFERENCE',
}

export enum EthHeaders {
  ETH_ADDRESS = 'x-imx-eth-address',
  ETH_SIGNATURE = 'x-imx-eth-signature',
}
