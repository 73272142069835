import * as t from 'io-ts';

import { fromEnum, HexadecimalString } from '../runtime';
import { ApiResponseCodec } from './common';

export enum MetadataTypes {
  Enum = 'enum',
  Text = 'text',
  Boolean = 'boolean',
  Continuous = 'continuous',
  Discrete = 'discrete',
}

/** @deprecated */
export const MetadataTypesT = fromEnum<MetadataTypes>(
  'MetadataTypes',
  MetadataTypes,
);

export const MetadataPropertyCodec = t.interface({
  name: t.string,
  type: MetadataTypesT,
  filterable: t.boolean,
});

/** @deprecated */
export const MetadataObjectCodec = t.intersection([
  t.type({
    /**
     * Name of the metadata key (required)
     */
    name: t.string,
  }),
  t.partial({
    /**
     * Type of the metadata. (required) Values: "enum", "text", "boolean", "continuous", "discrete"
     * Src: https://docs.x.immutable.com/docs/asset-metadata#property-type-mapping
     * Defaults to 'text'
     */
    type: t.union([t.undefined, MetadataTypesT]),
    /**
     * Sets the metadata as filterable
     * Defaults to false
     */
    filterable: t.union([t.undefined, t.boolean]),
  }),
]);

/**
 * Request parameters for adding metadata schema to a collection
 *
 * @deprecated
 */
export const AddMetadataSchemaToCollectionParamsCodec = t.type({
  /**
   * The metadata container (required)
   */
  metadata: t.array(MetadataObjectCodec),
});
/** @deprecated */
export type AddMetadataSchemaToCollectionParamsF = t.TypeOf<
  typeof AddMetadataSchemaToCollectionParamsCodec
>;
export type AddMetadataSchemaToCollectionParams = t.OutputOf<
  typeof AddMetadataSchemaToCollectionParamsCodec
>;

/**
 * Result of adding metadata schema to a collection
 *
 * @deprecated
 */
export const AddMetadataSchemaToCollectionResultCodec = ApiResponseCodec;
/** @deprecated */
export type AddMetadataSchemaToCollectionResultF = t.TypeOf<
  typeof ApiResponseCodec
>;
export type AddMetadataSchemaToCollectionResult = t.OutputOf<
  typeof ApiResponseCodec
>;

/**
 * Request parameters for updating metadata schema by name
 *
 * @deprecated
 */
export const UpdateMetadataSchemaByNameParamsCodec = t.partial({
  /**
   * The new metadata key if different from existing key
   */
  name: t.union([t.undefined, t.string]),
  /**
   * Type of the metadata. (required) Values: "enum", "text", "boolean", "continuous", "discrete"
   * Src: https://docs.x.immutable.com/docs/asset-metadata#property-type-mapping
   * Defaults to 'text'
   */
  type: t.union([t.undefined, MetadataTypesT]),
  /**
   * Sets the metadata as filterable
   * Defaults to false
   */
  filterable: t.union([t.undefined, t.boolean]),
});
/** @deprecated */
export type UpdateMetadataSchemaByNameParamsF = t.TypeOf<
  typeof UpdateMetadataSchemaByNameParamsCodec
>;
export type UpdateMetadataSchemaByNameParams = t.OutputOf<
  typeof UpdateMetadataSchemaByNameParamsCodec
>;

/**
 * Result of updating metadata schema by name
 *
 * @deprecated
 */
export const UpdateMetadataSchemaByNameResultCodec = ApiResponseCodec;
/** @deprecated */
export type UpdateMetadataSchemaByNameResultF = t.TypeOf<
  typeof ApiResponseCodec
>;
export type UpdateMetadataSchemaByNameResult = t.OutputOf<
  typeof ApiResponseCodec
>;

/** @deprecated */
export const GetMetadataSchemaParamsCodec = t.type({
  address: HexadecimalString,
});
/** @deprecated */
export type GetMetadataSchemaParamsF = t.TypeOf<
  typeof GetMetadataSchemaParamsCodec
>;
export type GetMetadataSchemaParams = t.OutputOf<
  typeof GetMetadataSchemaParamsCodec
>;

export const GetMetadataSchemaResultCodec = t.array(MetadataObjectCodec);
export type GetMetadataSchemaResultF = t.TypeOf<
  typeof GetMetadataSchemaResultCodec
>;
export type GetMetadataSchemaResult = t.OutputOf<
  typeof GetMetadataSchemaResultCodec
>;
