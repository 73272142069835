import * as t from 'io-ts';

/**
 * Request parameters for creating a collection
 *
 * @deprecated
 */
export const CreateCollectionParamsCodec = t.intersection([
  t.type({
    /**
     * Name of the collection (required)
     */
    name: t.string,
    /**
     * Ethereum address of the ERC721 contract (required, must be an ETH address)
     */
    contract_address: t.string,
    /**
     * Owner Public Key: The public key of the owner of the contract (required)
     */
    owner_public_key: t.string,
    /**
     * The collection's project ID (required)
     */
    project_id: t.number,
  }),
  t.partial({
    /**
     * URL of the metadata for this collection
     */
    metadata_api_url: t.string,
    /**
     * Description of the collection
     */
    description: t.string,
    /**
     * URL of the icon for this collection
     */
    icon_url: t.string,
    /**
     * URL of the tile image for this collection
     */
    collection_image_url: t.string,
  }),
]);

/** @deprecated */
export type CreateCollectionParamsF = t.TypeOf<
  typeof CreateCollectionParamsCodec
>;
export type CreateCollectionParams = t.OutputOf<
  typeof CreateCollectionParamsCodec
>;

/**
 * Result of creating a collection
 *
 * @deprecated
 */
export const CreateCollectionsResultsCodec = t.type({
  /**
   * Name of the collection (required)
   */
  name: t.string,
  /**
   * Ethereum address of the ERC721 contract
   */
  address: t.string,
  /**
   * The collection's project ID (required)
   */
  project_id: t.number,
  /**
   * URL of the metadata for this collection
   */
  metadata_api_url: t.union([t.null, t.string]),
  /**
   * Description of the collection
   */
  description: t.union([t.null, t.string]),
  /**
   * URL of the icon for this collection
   */
  icon_url: t.union([t.null, t.string]),
  /**
   * URL of the tile image for this collection
   */
  collection_image_url: t.union([t.null, t.string]),
});

/** @deprecated */
export type CreateCollectionsResultF = t.TypeOf<
  typeof CreateCollectionsResultsCodec
>;
export type CreateCollectionsResult = t.OutputOf<
  typeof CreateCollectionsResultsCodec
>;

/**
 * Request parameters for updating a collection
 */
export const UpdateCollectionParamsCodec = t.partial({
  /**
   * Name of the collection
   */
  name: t.union([t.undefined, t.string]),
  /**
   * Description of the collection
   */
  description: t.union([t.undefined, t.string]),
  /**
   * URL of the icon for this collection
   */
  icon_url: t.union([t.undefined, t.string]),
  /**
   * URL of the metadata for this collection
   * If metadata_api_url is modified AFTER minting, the existing NFTs that used the previous metadata_api_url will not be modified.
   * The new metadata_api_url will be used for new NFT mints.
   */
  metadata_api_url: t.union([t.undefined, t.string]),
  /**
   * URL of the tile image for this collection
   */
  collection_image_url: t.union([t.undefined, t.string]),
});
/** @deprecated */
export type UpdateCollectionParamsF = t.TypeOf<
  typeof UpdateCollectionParamsCodec
>;
export type UpdateCollectionParams = t.OutputOf<
  typeof UpdateCollectionParamsCodec
>;

/**
 * Result of updating a collection
 *
 * @deprecated
 */
export const UpdateCollectionsResultCodec = t.type({
  address: t.string,
  name: t.string,
  description: t.union([t.null, t.string]),
  icon_url: t.union([t.null, t.string]),
  collection_image_url: t.union([t.null, t.string]),
  project_id: t.number,
  metadata_api_url: t.union([t.null, t.string]),
});
/** @deprecated */
export type UpdateCollectionsResultF = t.TypeOf<
  typeof UpdateCollectionsResultCodec
>;
export type UpdateCollectionsResults = t.OutputOf<
  typeof UpdateCollectionsResultCodec
>;
