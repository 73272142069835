import { ExternalProvider } from '@ethersproject/providers';
import { Magic } from 'magic-sdk';

import { ProviderPreference } from '../types';
import { GameWalletProviderOptions } from './ethereum';

export const createGameWalletProvider = async (
  gamePreference: ProviderPreference,
  config: GameWalletProviderOptions[],
): Promise<ExternalProvider> => {
  const gameProviderOption = config.find(
    gameWalletProviderOption =>
      gameWalletProviderOption.providerPreference === gamePreference,
  );

  if (!gameProviderOption) {
    return Promise.reject(new Error('No game wallet preference found'));
  }

  const magicGameWallet = new Magic(gameProviderOption.publishableKey, {
    network: gameProviderOption.network,
  });
  return Promise.resolve(magicGameWallet.rpcProvider);
};
