import { ExternalProvider } from '@ethersproject/providers';
import { Magic } from 'magic-sdk';

import { MagicProviderOptions } from './ethereum';

export const createMagicProvider = async (
  config: MagicProviderOptions,
): Promise<ExternalProvider> => {
  const magic = new Magic(config.publishableKey, {
    network: config.network,
  });
  return Promise.resolve(magic.rpcProvider);
};
