import * as t from 'io-ts';

import { ImmutableMethodResults } from './types';

/** @deprecated */
export const CreateProjectParamsCodec = t.type({
  name: t.string,
  company_name: t.string,
  contact_email: t.string,
});
/** @deprecated */
export type CreateProjectParamsF = t.TypeOf<typeof CreateProjectParamsCodec>;
export type CreateProjectParams = t.OutputOf<typeof CreateProjectParamsCodec>;

/** @deprecated */
export const GetProjectParamsCodec = t.type({
  project_id: t.number,
});
/** @deprecated */
export type GetProjectParamsF = t.TypeOf<typeof GetProjectParamsCodec>;
export type GetProjectParams = t.OutputOf<typeof GetProjectParamsCodec>;

/** @deprecated */
export const CreateProjectResultsCodec = t.type({
  id: t.number,
});
/** @deprecated */
export type CreateProjectResultF = t.TypeOf<typeof CreateProjectResultsCodec>;
export type CreateProjectResult = t.OutputOf<typeof CreateProjectResultsCodec>;

/** @deprecated */
export const ProjectResultCodec = t.type({
  id: t.number,
  name: t.string,
  company_name: t.string,
  contact_email: t.string,
  mint_remaining: t.number,
  mint_limit_expires_at: t.union([t.null, t.string]),
  mint_monthly_limit: t.number,
  collection_remaining: t.number,
  collection_limit_expires_at: t.union([t.null, t.string]),
  collection_monthly_limit: t.number,
});
/** @deprecated */
export type ProjectResultF = t.TypeOf<typeof ProjectResultCodec>;
export type ProjectResult = t.OutputOf<typeof ProjectResultCodec>;

/** @deprecated */
export const ProjectsResultCodec = t.intersection([
  t.type({
    result: t.array(ProjectResultCodec),
  }),
  ImmutableMethodResults.ImmutablePaginatedResultCodec,
]);

/** @deprecated */
export type ProjectsResultF = t.TypeOf<typeof ProjectsResultCodec>;
export type ProjectsResult = t.OutputOf<typeof ProjectsResultCodec>;
