import { ExternalProvider } from '@ethersproject/providers';
import ImxWalletConnectQrCodeModel from '@imtbl/imx-walletconnect-qrcode-modal';
import { IWalletConnectProviderOptions } from '@walletconnect/types';
import WalletConnectProvider from '@walletconnect/web3-provider';

const DISCONNECT_MESSAGE = 'Wallet has been disconnected';

export const createWalletConnectProvider = async (
  walletConnectOptions: IWalletConnectProviderOptions,
): Promise<ExternalProvider> => {
  return new Promise(async (resolve, reject) => {
    const wcProvider = new WalletConnectProvider({
      ...walletConnectOptions,
      qrcodeModal: ImxWalletConnectQrCodeModel,
    });

    wcProvider.wc.on('disconnect', () => {
      wcProvider.isConnecting = false;
      reject(new WalletProviderError(DISCONNECT_MESSAGE));
    });

    wcProvider.on('error', error => {
      reject(new WalletProviderError(error));
    });

    try {
      await wcProvider.enable();
      resolve(wcProvider);
    } catch (error) {
      reject(error);
    }
  });
};

export class WalletProviderError extends Error {
  constructor(message) {
    super(message);
    this.name = 'WalletProviderError';
  }
}
