import { formatEther, parseEther } from '@ethersproject/units';
import axios, { AxiosError } from 'axios';
import { BigNumber } from 'ethers';
import { pipe } from 'fp-ts/function';

import {
  ERC20TokenType,
  ETHToken,
  ETHTokenType,
  PositiveBigNumber,
  PositiveNumberString,
  PositiveNumberStringC,
  Token,
} from '../types';
import { valueOrThrow } from './fp';

export const assistanceMessage =
  'For further assistance please visit the ImmutableX support page at https://support.immutable.com';

export function payloadId(): number {
  const datePart: number = new Date().getTime() * Math.pow(10, 3);
  const extraPart: number = Math.floor(Math.random() * Math.pow(10, 3));
  const id: number = datePart + extraPart;
  return id;
}

const axiosResponseErrorMessageMap: {
  [statusCode: number]: (data: any) => string;
} = {
  403: data =>
    data?.error ?? `This service is unavailable. ${assistanceMessage}`,
  429: _ => 'Rated limited',
};

export function formatError(error: AxiosError | Error): Error {
  if (axios.isAxiosError(error)) {
    if (error.response) {
      if (error.response.status in axiosResponseErrorMessageMap) {
        return new Error(
          axiosResponseErrorMessageMap[error.response.status](
            error.response.data,
          ),
        );
      }

      const dataCode = error.response.data?.code
        ? `(${error.response.data.code})`
        : '';
      const dataMessage =
        error.response.data?.message ??
        `The service rejected the request with the payload: ${JSON.stringify(
          error.response.data,
        )}\n${assistanceMessage}`;

      return new Error(`${dataMessage} ${dataCode}`.trim());
    }

    if (error.request) {
      return new Error(
        `Errno: ${(error as any)?.errno} Errcode: ${error?.code}`,
      );
    }
  }

  return new Error(error?.message);
}

export function sleep(time: number) {
  return new Promise(resolve => {
    setTimeout(resolve, time);
  });
}

export const ethToken: ETHToken = {
  type: ETHTokenType.ETH,
  data: {
    decimals: 18,
  },
};

export const eth8ToWei = (amount: PositiveBigNumber): PositiveBigNumber =>
  pipe(amount.mul(Math.pow(10, 8)), PositiveBigNumber.decode, valueOrThrow);

export const eth8ToEth = (amount: PositiveBigNumber): PositiveNumberString =>
  pipe(
    amount.mul(Math.pow(10, 8)),
    String,
    formatEther,
    PositiveNumberStringC.decode,
    valueOrThrow,
  );

export const weiToEth8 = (amount: PositiveBigNumber): PositiveBigNumber =>
  pipe(amount.div(Math.pow(10, 8)), PositiveBigNumber.decode, valueOrThrow);

export const ethToEth8 = (amount: PositiveNumberString): PositiveBigNumber =>
  pipe(parseEther(amount), PositiveBigNumber.decode, valueOrThrow, weiToEth8);

export const amountToQuantizedAmount = (
  amount: PositiveBigNumber,
  quantum: string,
): PositiveBigNumber => {
  if (quantum === '1') {
    return amount;
  }

  return pipe(
    amount.div(BigNumber.from(quantum)),
    PositiveBigNumber.decode,
    valueOrThrow,
  );
};

/**
 * NOTE: 'quantum' value is required for 'ERC-20' tokens,
 * and should be retreived from the '/tokens' API reponse.
 */
export const tokenQuantizedAmount = (
  token: Token,
  amount: PositiveBigNumber,
  quantum = '1',
): PositiveBigNumber => {
  switch (token.type) {
    case ETHTokenType.ETH: {
      return weiToEth8(amount);
    }

    case ERC20TokenType.ERC20: {
      return amountToQuantizedAmount(amount, quantum);
    }

    default: {
      return amount;
    }
  }
};
