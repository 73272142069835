import { joinSignature, splitSignature } from '@ethersproject/bytes';
import { PopulatedTransaction } from '@ethersproject/contracts';
import { NonceManager } from '@ethersproject/experimental';
import { keccak256 } from '@ethersproject/keccak256';
import { SigningKey } from '@ethersproject/signing-key';
import { keccak256 as solidityKeccak256 } from '@ethersproject/solidity';
import { toUtf8Bytes } from '@ethersproject/strings';
import autoBind from 'auto-bind';
import { ec } from 'elliptic';
import * as encUtils from 'enc-utils';
import { BigNumber } from 'ethers';
import * as E from 'fp-ts/Either';
import { constant, pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import * as t from 'io-ts';
import { Bytes32 } from 'soltypes';

import type { Stark } from '../contracts';
import { ImmutableX__factory, Stark__factory } from '../contracts';
import { Registration, Registration__factory } from '../contracts/registration';
import {
  DEFAULT_ACCOUNT_APPLICATION,
  DEFAULT_ACCOUNT_INDEX,
  DEFAULT_ACCOUNT_LAYER,
  DEFAULT_ACCOUNT_MAPPING_KEY,
  DEFAULT_SIGNATURE_MESSAGE,
  deserializeSignature,
  getAccountPath,
  getAssetType,
  getCancelOrderMsg,
  getDepositMsg,
  getKeyPairFromPath,
  getKeyPairFromPrivateKey,
  getLimitOrderMsg,
  getLimitOrderMsgWithFee,
  getMintingBlob,
  getRegisterUserMsg,
  getRegisterUserMsgVerifyEth,
  getStarkPublicKey,
  getTransferMsg,
  getWithdrawMsg,
  getXCoordinate,
  isHexPrefixed,
  serializeEthSignature,
  serializeSignature,
  sign,
} from '../crypto';
import {
  decodeForFunction,
  switchCase,
  taskEitherWithError,
  tokenQuantizedAmount,
  valueOrThrow,
} from '../libs';
import {
  ERC20TokenType,
  ERC721Token,
  ERC721TokenType,
  EthAddress,
  ETHTokenType,
  FeeParams,
  ImmutableMethodParams,
  ImmutableMethodResults,
  MintableERC20TokenType,
  MintableERC721Token,
  MintableERC721TokenType,
  OrderParams,
  PositiveBigNumber,
  RegistrationMethodParams,
  SignatureOptions,
  StarkMethodParams,
  StarkwareAccountMapping,
  Store,
  Token,
  Transaction,
  TransferParams,
} from '../types';
import { deleteItem, getItem, setItem } from '../utils/localstorage';
import { ImmutableXClient } from './ImmutableXClient';

/**
 * Immutable X Controller
 */
export class ImmutableXController {
  private accountMapping: StarkwareAccountMapping | undefined; // Map of { HD Path : KeyPair.getPrivate('hex') }

  private activeKeyPair: ec.KeyPair | undefined;

  private store: Store;

  constructor(
    private publicApiUrl: string,
    private signer: NonceManager,
    private accountMappingKey: string = DEFAULT_ACCOUNT_MAPPING_KEY,
  ) {
    if (!signer.provider) {
      throw new Error('Signer is required to have a provider!');
    }
    const storage: any = {};
    this.store = {
      set: async (key: string, data: any): Promise<void> => {
        storage[key] = data;
        setItem(key, data);
      },
      get: async (key: string): Promise<any> => {
        let returnValue = storage[key];

        // If not set, check if in localstorage
        if (!returnValue) {
          returnValue = getItem(key);
          storage[key] = returnValue;
        }
        return storage[key];
      },
      remove: async (key: string): Promise<void> => {
        delete storage[key];
        deleteItem(key);
      },
    };
    autoBind(this);
  }

  // -- Public -- //
  public async getAddress(): Promise<EthAddress> {
    return pipe(
      await this.signer.getAddress(),
      address => address.toLowerCase(),
      EthAddress.decode,
      valueOrThrow,
    );
  }

  get starkPublicKey(): string | undefined {
    if (!this.activeKeyPair) return undefined;
    return encUtils.sanitizeHex(
      getXCoordinate(getStarkPublicKey(this.activeKeyPair)),
    );
  }

  public async getStarkPublicKey(path?: string): Promise<string> {
    const keyPair = await this.getKeyPairFromPath(path);
    return encUtils.sanitizeHex(getXCoordinate(getStarkPublicKey(keyPair)));
  }

  public getExchangeContract(contractAddress: string): Stark {
    if (!this.signer.provider) {
      throw new Error('Please set a provider first - call setProvider()');
    }
    return Stark__factory.connect(contractAddress, this.signer);
  }

  public getRegistrationContract(contractAddress: string): Registration {
    if (!this.signer.provider) {
      throw new Error('Please set a provider first - call setProvider()');
    }
    return Registration__factory.connect(contractAddress, this.signer);
  }

  // Signing - via eth key
  public async sign(payload: any): Promise<string> {
    const hash = keccak256(toUtf8Bytes(JSON.stringify(payload)));
    const sig = deserializeSignature(await this.signer.signMessage(hash));
    return serializeEthSignature(sig);
  }

  // Signing - via eth key - TODO: EIP712
  public async signRaw(payload: string): Promise<string> {
    const sig = deserializeSignature(await this.signer.signMessage(payload));
    return serializeEthSignature(sig);
  }

  // Signing - via stark key
  public async signStark(payload: any): Promise<string> {
    const key = (await this.getActiveKeyPair()).getPrivate('hex');
    const signingKey = new SigningKey(isHexPrefixed(key) ? key : `0x${key}`);
    const hash = keccak256(toUtf8Bytes(JSON.stringify(payload)));
    const signature = joinSignature(signingKey.signDigest(hash));
    return signature;
  }

  // Signing - via eth key for registration
  public async signRegistration(): Promise<string> {
    const hash = solidityKeccak256(
      ['string', 'address', 'uint256'],
      [
        'UserRegistration:',
        await this.getAddress(),
        await this.getStarkPublicKey(),
      ],
    );
    const signature = await this.signer.signMessage(hash);
    return signature;
  }

  /**
   * StarkWare JSON-RPC Spec
   */
  // Stark Off-Chain
  public async account(
    layer: string,
    application: string,
    index: string,
  ): Promise<string> {
    const path = getAccountPath(
      layer,
      application,
      await this.getAddress(),
      index,
    );
    const starkPublicKey = await this.getStarkPublicKey(path);
    return starkPublicKey;
  }

  private async getCurrentAccountPath() {
    return getAccountPath(
      DEFAULT_ACCOUNT_LAYER,
      DEFAULT_ACCOUNT_APPLICATION,
      await this.getAddress(),
      DEFAULT_ACCOUNT_INDEX,
    );
  }

  public signUserRegistration({
    etherKey,
    starkPublicKey,
    nonce,
  }: {
    etherKey: string;
    starkPublicKey: string;
    nonce: string;
  }): TE.TaskEither<Error, string> {
    return pipe(
      taskEitherWithError(async () =>
        this.assertStarkPublicKey(starkPublicKey),
      ),
      TE.chain(() => taskEitherWithError(this.getActiveKeyPair)),
      TE.chain(keyPair => {
        const msg = getRegisterUserMsg(etherKey, starkPublicKey, nonce);
        const signature = sign(keyPair, msg);
        const starkSignature = serializeSignature(signature);
        return TE.of(starkSignature);
      }),
    );
  }

  public signUserRegistrationVerifyEth({
    etherKey,
    starkPublicKey,
  }: {
    etherKey: string;
    starkPublicKey: string;
  }): TE.TaskEither<Error, string> {
    return pipe(
      taskEitherWithError(async () =>
        this.assertStarkPublicKey(starkPublicKey),
      ),
      TE.chain(() => taskEitherWithError(this.getActiveKeyPair)),
      TE.chain(keyPair => {
        const msg = getRegisterUserMsgVerifyEth(etherKey, starkPublicKey);
        const signature = sign(keyPair, msg);
        const starkSignature = serializeSignature(signature);
        return TE.of(starkSignature);
      }),
    );
  }

  public async signDeposit(
    starkPublicKey: string,
    vaultId: string,
    assetId: string,
    quantity: PositiveBigNumber,
    nonce: string,
  ): Promise<string> {
    await this.assertStarkPublicKey(starkPublicKey);
    const msg = getDepositMsg(
      quantity.toString(),
      nonce,
      vaultId,
      assetId,
      starkPublicKey,
    );
    const keyPair = await this.getActiveKeyPair();
    const signature = sign(keyPair, msg);
    const starkSignature = serializeSignature(signature);
    return starkSignature;
  }

  public signWithdraw(
    starkPublicKey: string,
    vaultId: string,
    token: Token,
    assetId: string,
    quantity: PositiveBigNumber,
    nonce: string,
  ): TE.TaskEither<Error, string> {
    return taskEitherWithError(async () => {
      await this.assertStarkPublicKey(starkPublicKey);
      const msg = getWithdrawMsg(
        quantity.toString(),
        nonce,
        vaultId,
        assetId,
        starkPublicKey,
      );
      const keyPair = await this.getActiveKeyPair();
      const signature = sign(keyPair, msg);
      const starkSignature = serializeSignature(signature);
      return starkSignature;
    });
  }

  public transfer(
    from: TransferParams,
    to: TransferParams,
    token: Token,
    assetId: string,
    quantity: PositiveBigNumber,
    nonce: string,
    expirationTimestamp: string,
  ): TE.TaskEither<Error, string> {
    return taskEitherWithError(async () => {
      return this.transferAsync(
        from,
        to,
        token,
        assetId,
        quantity,
        nonce,
        expirationTimestamp,
      );
    });
  }

  public async transferAsync(
    from: TransferParams,
    to: TransferParams,
    token: Token,
    assetId: string,
    quantity: PositiveBigNumber,
    nonce: string,
    expirationTimestamp: string,
  ): Promise<string> {
    const quantum = await this.getQuantumValue(token);

    await this.assertStarkPublicKey(from.starkPublicKey);
    const quantizedAmount = tokenQuantizedAmount(token, quantity, quantum);
    const msg = getTransferMsg(
      quantizedAmount.toString(),
      nonce,
      String(from.vaultId),
      assetId,
      String(to.vaultId),
      to.starkPublicKey,
      expirationTimestamp,
    );

    const keyPair = await this.getActiveKeyPair();
    const signature = sign(keyPair, msg);
    const starkSignature = serializeSignature(signature);
    return starkSignature;
  }

  // Adds signatures to the transfer request
  public transferV2(
    transfers: ImmutableMethodResults.ImmutableGetSignableTransferV2Result,
  ): TE.TaskEither<
    Error,
    readonly ImmutableMethodParams.ImmutableTransferRequestV2[]
  > {
    return TE.traverseSeqArray(
      (transfer: ImmutableMethodResults.ImmutableSignableTransferV2) =>
        pipe(
          TE.bindTo('signature')(
            this.transfer(
              {
                starkPublicKey: transfers.sender_stark_key,
                vaultId: transfer.sender_vault_id,
              },
              {
                starkPublicKey: transfer.receiver_stark_key,
                vaultId: transfer.receiver_vault_id,
              },
              transfer.token,
              transfer.asset_id,
              transfer.amount,
              String(transfer.nonce),
              String(transfer.expiration_timestamp),
            ),
          ),
          TE.map(({ signature }: { signature: string }) => ({
            receiver_stark_key: transfer.receiver_stark_key,
            receiver_vault_id: transfer.receiver_vault_id,
            amount: String(transfer.amount),
            asset_id: transfer.asset_id,
            expiration_timestamp: transfer.expiration_timestamp,
            nonce: transfer.nonce,
            sender_vault_id: transfer.sender_vault_id,
            stark_signature: signature,
          })),
        ),
    )(transfers.signable_responses);
  }

  public createOrder(
    starkPublicKey: string,
    sell: OrderParams,
    buy: OrderParams,
    sell_id: string,
    buy_id: string,
    nonce: t.Int,
    expirationTimestamp: t.Int,
  ): TE.TaskEither<Error, string> {
    return taskEitherWithError(async () => {
      await this.assertStarkPublicKey(starkPublicKey);
      const msg = getLimitOrderMsg(
        String(sell.vaultId),
        String(buy.vaultId),
        sell.quantity.toString(),
        buy.quantity.toString(),
        sell_id,
        buy_id,
        String(nonce),
        String(expirationTimestamp),
      );
      const keyPair = await this.getActiveKeyPair();
      const signature = sign(keyPair, msg);
      const starkSignature = serializeSignature(signature);
      return starkSignature;
    });
  }

  public createOrderWithFee(
    starkPublicKey: string,
    sell: OrderParams,
    buy: OrderParams,
    sell_id: string,
    buy_id: string,
    nonce: t.Int,
    expirationTimestamp: t.Int,
    fee_info: FeeParams,
  ): TE.TaskEither<Error, string> {
    return taskEitherWithError(async () => {
      await this.assertStarkPublicKey(starkPublicKey);
      const msg = getLimitOrderMsgWithFee({
        vaultSell: String(sell.vaultId),
        vaultBuy: String(buy.vaultId),
        amountSell: sell.quantity.toString(),
        amountBuy: buy.quantity.toString(),
        tokenSell: sell_id,
        tokenBuy: buy_id,
        nonce: String(nonce),
        expirationTimestamp: String(expirationTimestamp),
        feeToken: fee_info.feeToken,
        feeVault: String(fee_info.feeVaultId),
        feeLimit: fee_info.feeLimit.toString(),
      });
      const keyPair = await this.getActiveKeyPair();
      const signature = sign(keyPair, msg);
      const starkSignature = serializeSignature(signature);
      return starkSignature;
    });
  }

  public createOrderV3(
    starkPublicKey: string,
    sell_vault_id: t.Int,
    buy_vault_id: t.Int,
    sell_amount: BigNumber,
    buy_amount: BigNumber,
    sell_id: string,
    buy_id: string,
    nonce: t.Int,
    expirationTimestamp: t.Int,
  ): TE.TaskEither<Error, string> {
    return taskEitherWithError(async () => {
      await this.assertStarkPublicKey(starkPublicKey);
      const msg = getLimitOrderMsg(
        sell_vault_id.toString(),
        buy_vault_id.toString(),
        sell_amount.toString(),
        buy_amount.toString(),
        sell_id,
        buy_id,
        String(nonce),
        String(expirationTimestamp),
      );
      const keyPair = await this.getActiveKeyPair();
      const signature = sign(keyPair, msg);
      const starkSignature = serializeSignature(signature);
      return starkSignature;
    });
  }

  public createOrderWithFeeV3(
    starkPublicKey: string,
    sell_vault_id: t.Int,
    buy_vault_id: t.Int,
    sell_amount: BigNumber,
    buy_amount: BigNumber,
    sell_id: string,
    buy_id: string,
    nonce: t.Int,
    expirationTimestamp: t.Int,
    fee_info: FeeParams,
  ): TE.TaskEither<Error, string> {
    return taskEitherWithError(async () => {
      await this.assertStarkPublicKey(starkPublicKey);
      const msg = getLimitOrderMsgWithFee({
        vaultSell: String(sell_vault_id),
        vaultBuy: String(buy_vault_id),
        amountSell: sell_amount.toString(),
        amountBuy: buy_amount.toString(),
        tokenSell: sell_id,
        tokenBuy: buy_id,
        nonce: String(nonce),
        expirationTimestamp: String(expirationTimestamp),
        feeToken: fee_info.feeToken,
        feeVault: String(fee_info.feeVaultId),
        feeLimit: fee_info.feeLimit.toString(),
      });
      const keyPair = await this.getActiveKeyPair();
      const signature = sign(keyPair, msg);
      const starkSignature = serializeSignature(signature);
      return starkSignature;
    });
  }

  private getCancelOrderMsg(orderId: string): TE.TaskEither<Error, string> {
    return pipe(
      E.tryCatch(
        () => getCancelOrderMsg(orderId),
        e => e as Error,
      ),
      TE.fromEither,
    );
  }

  private signF({
    keyPair,
    msg,
  }: {
    keyPair: ec.KeyPair;
    msg: string;
  }): TE.TaskEither<Error, ec.Signature> {
    return pipe(
      E.tryCatch(
        () => sign(keyPair, msg),
        e => e as Error,
      ),
      TE.fromEither,
    );
  }

  private serializeSignature(signature: SignatureOptions) {
    return pipe(
      E.tryCatch(
        () => serializeSignature(signature),
        e => e as Error,
      ),
      TE.fromEither,
    );
  }

  public cancelOrder(orderId: string): TE.TaskEither<Error, string> {
    return pipe(
      this.getCancelOrderMsg(orderId),
      TE.map(msg => ({ msg })),
      TE.bind(
        'keyPair',
        constant(
          TE.tryCatch(
            () => this.getActiveKeyPair(),
            e => e as Error,
          ),
        ),
      ),
      TE.chain(this.signF),
      TE.chain(this.serializeSignature),
    );
  }

  // Stark On-Chain
  public register({
    contractAddress,
    etherKey,
    starkPublicKey,
    operatorSignature,
  }: StarkMethodParams.StarkRegisterParams): TE.TaskEither<
    Error,
    PopulatedTransaction
  > {
    return taskEitherWithError(() => {
      const exchangeContract = this.getExchangeContract(contractAddress);
      return exchangeContract.populateTransaction.registerUser(
        etherKey,
        starkPublicKey,
        operatorSignature,
      );
    });
  }

  public depositF({
    contractAddress,
    starkPublicKey,
    quantity,
    quantizedAmount,
    assetId,
    token,
    vaultId,
  }: StarkMethodParams.StarkDepositParams): TE.TaskEither<
    Error,
    PopulatedTransaction
  > {
    const exchangeContract = this.getExchangeContract(contractAddress);
    const assetType =
      token.type === ERC721TokenType.ERC721
        ? getAssetType(token).toUint().val
        : new Bytes32(assetId).toUint().val;

    return switchCase<string, TE.TaskEither<Error, PopulatedTransaction>>(
      token.type,
      {
        [ETHTokenType.ETH]: () =>
          pipe(
            taskEitherWithError(() =>
              exchangeContract.populateTransaction[
                'deposit(uint256,uint256,uint256)'
              ](starkPublicKey, assetType, vaultId),
            ),
            TE.map(unsignedTrx => ({
              ...unsignedTrx,
              value: quantity,
            })),
          ),
        [ERC20TokenType.ERC20]: () =>
          taskEitherWithError(() =>
            exchangeContract.populateTransaction[
              'deposit(uint256,uint256,uint256,uint256)'
            ](starkPublicKey, assetType, vaultId, quantizedAmount),
          ),
        [ERC721TokenType.ERC721]: () =>
          taskEitherWithError(() =>
            exchangeContract.populateTransaction.depositNft(
              starkPublicKey,
              assetType,
              vaultId,
              (token as ERC721Token).data.tokenId,
            ),
          ),
      },
      () => TE.left(new Error('Invalid token type')),
    );
  }

  public async deposit(
    params: StarkMethodParams.StarkDepositParamsTS,
  ): Promise<Transaction> {
    return decodeForFunction(
      params,
      StarkMethodParams.StarkDepositParamsCodec.decode,
      this.depositF,
    );
  }

  public registerAndDepositF({
    registrationContractAddress,
    starkPublicKey,
    quantity,
    assetId,
    token,
    vaultId,
    etherKey,
    operatorSignature,
  }: RegistrationMethodParams.RegisterAndDepositParams): TE.TaskEither<
    Error,
    PopulatedTransaction
  > {
    const registrationContract = this.getRegistrationContract(
      registrationContractAddress,
    );
    const assetType =
      token.type === ERC721TokenType.ERC721
        ? getAssetType(token).toUint().val
        : new Bytes32(assetId).toUint().val;

    return switchCase<string, TE.TaskEither<Error, PopulatedTransaction>>(
      token.type,
      {
        [ETHTokenType.ETH]: () =>
          pipe(
            taskEitherWithError(() =>
              registrationContract.populateTransaction[
                'registerAndDeposit(address,uint256,bytes,uint256,uint256)'
              ](
                etherKey,
                starkPublicKey,
                operatorSignature,
                assetType,
                vaultId,
              ),
            ),
            TE.map(unsignedTrx => ({
              ...unsignedTrx,
              value: quantity,
            })),
          ),
        [ERC20TokenType.ERC20]: () => TE.left(new Error('Invalid token type')),
        [ERC721TokenType.ERC721]: () =>
          TE.left(new Error('Invalid token type')),
      },
      () => TE.left(new Error('Invalid token type')),
    );
  }

  public async depositCancel(
    contractAddress: string,
    starkPublicKey: string,
    token: Token,
    vaultId: string,
  ): Promise<Transaction> {
    await this.assertStarkPublicKey(starkPublicKey);
    const exchangeContract = this.getExchangeContract(contractAddress);
    const assetType = getAssetType(token).toUint().val;
    const unsignedTrx: Transaction =
      await exchangeContract.populateTransaction.depositCancel(
        starkPublicKey,
        assetType,
        vaultId,
      );
    return unsignedTrx;
  }

  public async depositReclaim(
    contractAddress: string,
    starkPublicKey: string,
    token: Token,
    vaultId: string,
  ): Promise<Transaction> {
    await this.assertStarkPublicKey(starkPublicKey);
    const exchangeContract = this.getExchangeContract(contractAddress);
    const assetType = getAssetType(token).toUint().val;
    const unsignedTrx: Transaction =
      await exchangeContract.populateTransaction.depositReclaim(
        starkPublicKey,
        assetType,
        vaultId,
      );
    return unsignedTrx;
  }

  public withdrawal(
    contractAddress: string,
    starkPublicKey: string,
    token: Token,
  ): TE.TaskEither<Error, PopulatedTransaction> {
    return taskEitherWithError(async () => {
      await this.assertStarkPublicKey(starkPublicKey);
      const assetTypeValue = await this.getAssetTypeValue(token);
      const exchangeContract = this.getExchangeContract(contractAddress);
      if (
        token.type.toUpperCase() === ETHTokenType.ETH ||
        token.type.toUpperCase() === ERC20TokenType.ERC20
      ) {
        return exchangeContract.populateTransaction.withdraw(
          starkPublicKey,
          assetTypeValue,
        );
      }
      if (token.type.toUpperCase() === ERC721TokenType.ERC721) {
        return exchangeContract.populateTransaction.withdrawNft(
          starkPublicKey,
          assetTypeValue,
          (token as ERC721Token).data.tokenId,
        );
      }
      if (
        token.type.toUpperCase() === MintableERC20TokenType.MINTABLE_ERC20 ||
        token.type.toUpperCase() === MintableERC721TokenType.MINTABLE_ERC721
      ) {
        const mintToken = token as MintableERC721Token; // MintableERC20Token
        return exchangeContract.populateTransaction.withdrawAndMint(
          starkPublicKey,
          assetTypeValue,
          getMintingBlob(mintToken.data.id, mintToken.data.blueprint), // client token id, blueprint
        );
      }
      throw new Error('Invalid token type');
    });
  }

  public registerAndWithdraw({
    registrationContractAddress,
    starkPublicKey,
    token,
    etherKey,
    operatorSignature,
  }: RegistrationMethodParams.RegisterAndWithdrawParams): TE.TaskEither<
    Error,
    PopulatedTransaction
  > {
    return taskEitherWithError(async () => {
      await this.assertStarkPublicKey(starkPublicKey);
      const assetTypeValue = await this.getAssetTypeValue(token);
      const registrationContract = this.getRegistrationContract(
        registrationContractAddress,
      );

      if (
        token.type.toUpperCase() === ETHTokenType.ETH ||
        token.type.toUpperCase() === ERC20TokenType.ERC20
      ) {
        return registrationContract.populateTransaction.registerAndWithdraw(
          etherKey,
          starkPublicKey,
          operatorSignature,
          assetTypeValue,
        );
      }
      throw new Error('Invalid token type');
    });
  }

  public async fullWithdrawal(
    contractAddress: string,
    starkPublicKey: string,
    vaultId: string,
  ): Promise<Transaction> {
    await this.assertStarkPublicKey(starkPublicKey);
    const exchangeContract = this.getExchangeContract(contractAddress);
    const unsignedTrx: Transaction =
      await exchangeContract.populateTransaction.fullWithdrawalRequest(
        starkPublicKey,
        vaultId,
      );
    return unsignedTrx;
  }

  public async freeze(
    contractAddress: string,
    starkPublicKey: string,
    vaultId: string,
  ): Promise<Transaction> {
    await this.assertStarkPublicKey(starkPublicKey);
    const exchangeContract = this.getExchangeContract(contractAddress);
    const unsignedTrx: Transaction =
      await exchangeContract.populateTransaction.freezeRequest(
        starkPublicKey,
        vaultId,
      );
    return unsignedTrx;
  }

  // public async verifyEscape(contractAddress: string, starkPublicKey: string, proof: string[]): Promise<Transaction> {
  //     await this.assertStarkPublicKey(starkPublicKey);
  //     const exchangeContract = this.getExchangeContract(contractAddress);
  //     const unsignedTrx: Transaction = await exchangeContract.populateTransaction.verifyEscape(proof);
  //     return unsignedTrx;
  // }

  public async escape(
    contractAddress: string,
    starkPublicKey: string,
    vaultId: string,
    token: Token,
    quantity: PositiveBigNumber,
  ): Promise<Transaction> {
    await this.assertStarkPublicKey(starkPublicKey);
    const exchangeContract = this.getExchangeContract(contractAddress);
    const assetType = getAssetType(token).toUint().val;
    const quantizedAmount = tokenQuantizedAmount(token, quantity);
    const unsignedTrx: Transaction =
      await exchangeContract.populateTransaction.escape(
        starkPublicKey,
        vaultId,
        assetType,
        quantizedAmount.toString(),
      );
    return unsignedTrx;
  }

  // Immutable NFT (ERC-721) Contract
  public mintNFT(tokenAddress: string): TE.TaskEither<Error, Transaction> {
    const nftContract = ImmutableX__factory.connect(tokenAddress, this.signer);
    return taskEitherWithError(() => nftContract.populateTransaction.mint());
  }

  public approveNFT({
    tokenAddress,
    contractAddress,
    tokenId,
  }: StarkMethodParams.StarkApproveNFTParams): TE.TaskEither<
    Error,
    Transaction
  > {
    const nftContract = ImmutableX__factory.connect(tokenAddress, this.signer);
    return taskEitherWithError(() =>
      nftContract.populateTransaction.approve(contractAddress, tokenId),
    );
  }

  public approveERC20({
    tokenAddress,
    amount,
    contractAddress,
  }: StarkMethodParams.StarkApproveERC20Params): TE.TaskEither<
    Error,
    Transaction
  > {
    const tokenContract = ImmutableX__factory.connect(
      tokenAddress,
      this.signer,
    );
    return taskEitherWithError(() =>
      tokenContract.populateTransaction.approve(contractAddress, amount),
    );
  }

  // -- Private -- //
  private async assertStarkPublicKey(starkPublicKey: string): Promise<void> {
    if (starkPublicKey !== (await this.getStarkPublicKey())) {
      throw new Error(
        'Provided Stark key does not match with the current active key',
      );
    }
  }

  private async getActiveKeyPair(): Promise<ec.KeyPair> {
    await this.getAccountMapping();
    if (this.activeKeyPair) {
      return this.activeKeyPair;
    }
    throw new Error('No active Stark key pair - Please provide a path');
  }

  private async setActiveKeyPair(
    path: string,
    activeKeyPair: ec.KeyPair,
  ): Promise<void> {
    const accountMapping = await this.getAccountMapping();
    accountMapping[path] = activeKeyPair.getPrivate('hex');
    this.accountMapping = accountMapping;
    this.activeKeyPair = activeKeyPair;
    await this.store.set(this.accountMappingKey, accountMapping);
  }

  private async getKeyPairFromPath(path?: string): Promise<ec.KeyPair> {
    const accountMapping = await this.getAccountMapping();

    // IF EXIST
    if (!path) {
      return this.getActiveKeyPair();
    }
    const match = accountMapping[path];
    if (match) {
      return getKeyPairFromPrivateKey(match);
    }

    // IF NOT EXIST
    /**
     * Creates an instance based on a seed.
     *
     * For the seed we suggest to use [bip39](https://npmjs.org/package/bip39) to
     * create one from a BIP39 mnemonic.
     */
    // e.g. bip39.generateMnemonic()
    // const activeKeyPair = crypto.getKeyPairFromPath(this.wallet.mnemonic.phrase, path); // ( seed, path e.g. m/44'/0'/0/1 )

    // const mnemonic = bip39.generateMnemonic();
    // log.debug(component, `Random mnemonic: ${mnemonic} and HD path: ${path}`);

    const signature = await this.signer.signMessage(DEFAULT_SIGNATURE_MESSAGE);
    const activeKeyPair = getKeyPairFromPath(splitSignature(signature).s, path); // || signature || utils.splitSignature(signature).r
    await this.setActiveKeyPair(path, activeKeyPair);
    return activeKeyPair;
  }

  private async getAccountMapping(): Promise<StarkwareAccountMapping> {
    if (typeof this.accountMapping !== 'undefined') {
      return this.accountMapping;
    }

    const accountMapping: StarkwareAccountMapping =
      (await this.store.get(this.accountMappingKey)) || {};
    this.accountMapping = accountMapping;

    const paths = Object.keys(accountMapping);
    const currentPath = await this.getCurrentAccountPath();

    if (!this.activeKeyPair && paths.length) {
      this.activeKeyPair = getKeyPairFromPrivateKey(
        accountMapping[currentPath],
      );
    }
    return accountMapping;
  }

  private isTokenERC20(token: Token): boolean {
    return token.type === ERC20TokenType.ERC20;
  }

  private isTokenETH(token: Token): boolean {
    return token.type === ETHTokenType.ETH;
  }

  private isTokenNFT(token: Token): boolean {
    return !this.isTokenERC20(token) && !this.isTokenETH(token);
  }

  private async getAssetTypeValue(token: Token): Promise<string> {
    return new Promise(async resolve => {
      const quantum = await this.getQuantumValue(token);
      const assetType = getAssetType(token, quantum);
      return resolve(assetType.toUint().val);
    });
  }

  private async getQuantumValue(token: Token): Promise<string> {
    return new Promise(async resolve => {
      const returnDefaultValue = () => resolve('1');
      if (this.isTokenNFT(token)) {
        return returnDefaultValue();
      }

      const client = await ImmutableXClient.build({
        publicApiUrl: this.publicApiUrl,
      });

      try {
        const { tokenAddress } = (token as any).data;
        const { quantum: qStr } = await client.getToken({
          tokenAddress,
        });

        return resolve(qStr);
      } catch (error: any) {
        console.error('Could not query quantum!');
        console.error('message:', error?.message);
        return returnDefaultValue();
      }
    });
  }
}
