import { ExternalProvider } from '@ethersproject/providers/lib/web3-provider';

import { UnexpectedProviderError } from './ethereum';

export const createExtensionProvider = async ({
  mustBeMetaMask,
}: {
  mustBeMetaMask?: boolean;
}): Promise<ExternalProvider | null> => {
  const { ethereum } = window as any;
  if (!ethereum) {
    return null;
  }

  let provider = null;
  if (ethereum.providers) {
    console.log('Multiple Ethereum providers detected');
    provider = ethereum.providers
      .reverse() // In order to prioritise MetaMask over Brave's version of MetaMask when both exist
      .find((p: any) => (mustBeMetaMask ? p.isMetaMask : true));

    ethereum.setSelectedProvider(provider);
  } else if (mustBeMetaMask && ethereum.isMetaMask !== true) {
    throw new UnexpectedProviderError('No metamask provider is detected');
  } else if (
    !mustBeMetaMask ||
    (mustBeMetaMask && ethereum.isMetaMask === true)
  ) {
    console.log('Ethereum provider detected');
    provider = ethereum;
  }

  return provider;
};
