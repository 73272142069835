export const erc20 = [
  // Get the token decimals
  'function decimals() view returns (uint8)',

  // Get the account balance
  'function balanceOf(address account) view returns (uint256)',

  // Get the allowance
  'function allowance(address _owner, address _spender) view returns (uint256 remaining)',
];
