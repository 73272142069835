import autoBind from 'auto-bind';
import axios, { AxiosRequestConfig } from 'axios';
import * as E from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import * as https from 'https';
import { Errors } from 'io-ts';
import queryString from 'query-string';

import {
  errorsToError,
  formatError,
  taskEitherWithError,
  valueOrThrow,
} from '../libs';
import { IMXDClientMethodParams, IMXDClientMethodResults } from '../types';

export type ImmutableIMXDClientParams = {
  imxdPublicApiUrl: string;
};

export class ImmutableIMXDClient {
  private agent: https.Agent;

  constructor(private imxdPublicApiUrl: string) {
    axios.defaults.baseURL = this.imxdPublicApiUrl;
    this.agent = new https.Agent();
    autoBind(this);
  }

  public static buildF(
    params: ImmutableIMXDClientParams,
  ): TE.TaskEither<Error, ImmutableIMXDClient> {
    return taskEitherWithError(() => ImmutableIMXDClient.build(params));
  }

  public static async build({ imxdPublicApiUrl }: ImmutableIMXDClientParams) {
    return new ImmutableIMXDClient(imxdPublicApiUrl);
  }

  public buildOptions(): AxiosRequestConfig {
    return {
      httpsAgent: this.agent,
      headers: {
        'Content-Type': 'application/json',
      },
    };
  }

  private get<T>(
    path: string,
    decode: (x: any) => E.Either<Errors, T>,
  ): TE.TaskEither<Error, T> {
    return pipe(
      taskEitherWithError(() => axios.get(`${path}`, this.buildOptions())),
      TE.chain(r =>
        pipe(decode(r.data), E.mapLeft(errorsToError), TE.fromEither),
      ),
    );
  }

  private getDailyPointsBalanceF(
    params: IMXDClientMethodParams.GetDailyPointsBalanceParams,
  ): TE.TaskEither<Error, IMXDClientMethodResults.GetDailyPointsBalanceResult> {
    const url = queryString.stringifyUrl({
      url: `daily-points-balance/${params.starkPublicKey}`,
      query: { date: params.date },
    });
    return this.get(
      url,
      IMXDClientMethodResults.GetDailyPointsBalanceResultCodec.decode,
    );
  }

  public async getDailyPointsBalance(
    params: IMXDClientMethodParams.GetDailyPointsBalanceParamsTS,
  ): Promise<IMXDClientMethodResults.GetDailyPointsBalanceResultTS> {
    return this.decodeForFunction(
      params,
      IMXDClientMethodParams.GetDailyPointsBalanceParamsCodec.decode,
      this.getDailyPointsBalanceF,
    );
  }

  private async decodeForFunction<T, U>(
    params: any,
    decode: (x: any) => E.Either<Errors, T>,
    fn: (params: T) => TE.TaskEither<Error, U>,
  ): Promise<U> {
    return valueOrThrow(
      await pipe(
        decode(params),
        E.mapLeft(errorsToError),
        TE.fromEither,
        TE.chain<Error, T, U>(fn),
        TE.mapLeft(formatError),
      )(),
    );
  }
}
