import * as E from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';

import { EthHeaders } from '../libs/constants';

/**
 * Mutate the headers param to add two more headers
 * @param headers {Object} headers object
 * @param ethAddress {string}
 * @param ethSignature {string}
 */
export const addEthHeaders = (
  headers: any,
  ethAddress: string,
  ethSignature: string,
) => {
  if (ethAddress && ethSignature) {
    return {
      ...headers,
      [EthHeaders.ETH_ADDRESS]: ethAddress,
      [EthHeaders.ETH_SIGNATURE]: ethSignature,
    };
  }
  return headers;
};

export function signMessageFactory(
  signFn: (a: string) => Promise<string>,
  ethAddress: () => Promise<string>,
  shouldSign,
): (
  message: string,
) => TE.TaskEither<
  Error,
  { message: string; ethSignature: string; ethAddress: string }
> {
  if (!shouldSign) {
    return (message: string) =>
      TE.of({
        message,
        ethSignature: '',
        ethAddress: '',
      });
  }
  return (data: string) => signMessage(data, ethAddress, signFn);
}

export function signMessage(
  data: string,
  getEthAddress: () => Promise<string>,
  sign: (a: string) => Promise<string>,
) {
  return pipe(
    {
      message: data,
    },
    E.of,
    TE.fromEither,
    TE.bind('ethSignature', ({ message }) =>
      TE.tryCatch(
        () => sign(message),
        (e: string) => new Error(e),
      ),
    ),
    TE.bind('ethAddress', () =>
      TE.tryCatch(
        () => getEthAddress(),
        (e: string) => new Error(e),
      ),
    ),
    TE.map(({ ethSignature, ethAddress, message }) => {
      return {
        message,
        ethAddress,
        ethSignature,
      };
    }),
  );
}
