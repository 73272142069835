import * as A from 'fp-ts/Array';
import * as E from 'fp-ts/Either';
import { Lazy, pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import { join } from 'fp-ts-std/Array';
import { Errors } from 'io-ts';
import * as t from 'io-ts';
import reporter from 'io-ts-reporters';

// Convert functional to procedural code >:)
export function valueOrThrow<E, A>(res: E.Either<E, A>): A {
  if (E.isRight(res)) {
    return res.right;
  }
  // eslint-disable-next-line @typescript-eslint/no-throw-literal
  throw res.left;
}

// Convert TE.TaskEither into a simple promise then return it's value
export async function valueOrThrowTE<E, R>(
  taskEither: TE.TaskEither<E, R>,
): Promise<R> {
  return valueOrThrow(await taskEither());
}

export const errorsToError = (errors: t.Errors): Error =>
  pipe(
    errors,
    errorsToArray,
    A.map(e => e.message),
    join('. '),
    message => new Error(message),
  );

export function errorsToArray(errors: t.Errors): Error[] {
  const result = reporter.report(E.left(errors));
  if (result.length === 0) {
    return [];
  }
  return result.map(r => new Error(r));
}

export async function decodeForFunction<T, U>(
  params: any,
  decode: (x: any) => E.Either<Errors, T>,
  fn: (params: T) => TE.TaskEither<Error, U>,
): Promise<U> {
  return valueOrThrow(
    await pipe(
      decode(params),
      E.mapLeft(errorsToError),
      TE.fromEither,
      TE.chain<Error, T, U>(fn),
    )(),
  );
}

export const logPipe = <T>(x: T): T => {
  console.log(x);
  return x;
};

export const logTE = <E, A>(te: TE.TaskEither<E, A>): TE.TaskEither<E, A> =>
  TE.bimap<E, E, A, A>(
    e => {
      console.error('logTE Error');
      console.dir(e, { depth: null });
      return e;
    },
    x => {
      console.log('logTE Info');
      console.dir(x, { depth: null });
      return x;
    },
  )(te);

export const logE = <E, A>(te: E.Either<E, A>): E.Either<E, A> =>
  E.bimap<E, E, A, A>(
    e => {
      console.error('logE Error');
      console.error(e);
      return e;
    },
    x => {
      console.log('logE Info');
      console.log(x);
      return x;
    },
  )(te);

export function taskEitherWithError<T>(f: Lazy<Promise<T>>) {
  return TE.tryCatch(f, error => error as Error);
}

export const switchCase = <T extends string | number | symbol, U>(
  value: T,
  cases: Record<T, Lazy<U>>,
  def: Lazy<U>,
) => (value in cases ? cases[value]() : def());

export function assertNever(x: never): never {
  throw new Error(`Unexpected object: ${x}`);
}

export const assertEither = (
  condition: boolean,
  errorMessage: string,
): E.Either<Error, null> =>
  condition ? E.left(new Error(errorMessage)) : E.right(null);
