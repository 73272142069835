import * as t from 'io-ts';

import { EthAddress, fromEnum } from '../runtime';

export enum RiskAssessmentHeader {
  AndroidSDKPrefix = 'imx-core-sdk-android',
  WalletSDKPrefix = 'x-wallet-sdk-version',
}

export enum MFAOption {
  Email = 'EMAIL',
}

export const MFAOptionTypeT = fromEnum<MFAOption>('MFAOption', MFAOption);

export enum AwaitingOrderStatus {
  Settled = 'settled',
  AwaitingApproval = 'awaiting_approval',
  Expired = 'expired',
  MFATriggerFailed = 'mfa_trigger_failed',
  Confirmed = 'confirmed',
  SettlementFailed = 'settlement_failed',
}

export const AwaitingOrderStatusTypeT = fromEnum<AwaitingOrderStatus>(
  'AwaitingOrderStatus',
  AwaitingOrderStatus,
);

export type VerifyOTPParams = t.OutputOf<typeof VerifyOTPParamsCodec>;

export const VerifyOTPParamsCodec = t.partial({
  /**
   * MFA OPTION
   */
  mfa_option: MFAOptionTypeT,
  /**
   * Ether address of the request
   */
  ether_key: EthAddress,
  /**
   * Request ID for current async request reference
   */
  request_id: t.string,
  /**
   * 6 digits OTP
   */
  code: t.string,
});

export type VerifyOTPResponse = t.OutputOf<typeof VerifyOTPResponseCodec>;

export const VerifyOTPResponseCodec = t.partial({
  /**
   * Request ID for current async request reference
   */
  request_id: t.string,
  /**
   * status of verification result
   */
  status: t.string,
});

export type GetAwaitingOrderStatusResponse = t.OutputOf<
  typeof GetAwaitingOrderStatusResponseCodec
>;

export const GetAwaitingOrderStatusResponseCodec = t.partial({
  status: AwaitingOrderStatusTypeT,
});
