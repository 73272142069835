import * as t from 'io-ts';

import { HexadecimalString } from './runtime';

export namespace IMXDClientMethodParams {
  export const GetDailyPointsBalanceParamsCodec = t.type({
    starkPublicKey: HexadecimalString,
    date: t.string,
  });
  export type GetDailyPointsBalanceParams = t.TypeOf<
    typeof GetDailyPointsBalanceParamsCodec
  >;
  export type GetDailyPointsBalanceParamsTS = t.OutputOf<
    typeof GetDailyPointsBalanceParamsCodec
  >;
}

export namespace IMXDClientMethodResults {
  export const GetDailyPointsBalanceResultCodec = t.type({
    daily_points_balance: t.string,
    date: t.string,
    starkKey: HexadecimalString,
  });
  export type GetDailyPointsBalanceResult = t.TypeOf<
    typeof GetDailyPointsBalanceResultCodec
  >;
  export type GetDailyPointsBalanceResultTS = t.OutputOf<
    typeof GetDailyPointsBalanceResultCodec
  >;
}
