/* eslint-disable no-param-reassign */
import { ImLogger } from '@imtbl/imlogging';
import autoBind from 'auto-bind';
import * as http from 'http';
import WebSocket from 'ws';

/**
 * Immutable X Web Socket Server
 */
interface CustomSocket extends WebSocket {
  isAlive: boolean;
}

export class ImmutableXServer {
  private wss: WebSocket.Server;

  private component = 'ImmutableXServer';

  constructor(private log: ImLogger, port = 8080) {
    this.log.debug(this.component, 'Immutable X Web Socket Server');
    this.wss = new WebSocket.Server({ port });
    autoBind(this);

    this.wss.on('connection', this.handleConnection);
  }

  private handleConnection(socket: CustomSocket, req: http.IncomingMessage) {
    this.log.debug(
      this.component,
      `Connected to ${req.socket.remoteAddress}...`,
    );
    socket.isAlive = true;

    socket.on('message', this.handleMessage);

    socket.on('close', () => {
      this.log.debug(
        this.component,
        `Disconnected from ${req.socket.remoteAddress}...`,
      );
    });
  }

  private handleMessage(message: string) {
    this.log.debug(this.component, message);
    this.wss.clients.forEach((client: WebSocket) => {
      if (client.readyState === WebSocket.OPEN) {
        client.send(message);
      }
    });
  }
}
