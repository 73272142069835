/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
import { Signer } from '@ethersproject/abstract-signer';
import { keccak256 as solidityKeccak256 } from '@ethersproject/solidity';
import assert from 'assert';
import { BigNumber } from 'bignumber.js';
import bitwise from 'bitwise';
import BN from 'bn.js';
import { ec } from 'elliptic';
import * as encUtils from 'enc-utils';
import { hdkey } from 'ethereumjs-wallet';
import hashJS from 'hash.js';
import { Bytes32 } from 'soltypes';

import {
  ERC20Token,
  ERC721Token,
  MintableERC20Token,
  MintableERC721Token,
  SignatureOptions,
  Token,
} from '../types';
import {
  constantPoints,
  instructionEncodingMap,
  MAX_ECDSA_BN,
  MINTABLE_ASSET_ID_PREFIX,
  MISSING_HEX_PREFIX,
  NFT_ASSET_ID_PREFIX,
  ONE_BN,
  ORDER,
  prime,
  PRIME_BN,
  SECP_ORDER,
  shiftPoint,
  starkEc,
  TWO_POW_22_BN,
  TWO_POW_31_BN,
  TWO_POW_63_BN,
  ZERO_BN,
} from './constants';
import {
  Instruction,
  InstructionWithFee,
  LimitOrderWithFeeParams,
} from './types';

export function isHexPrefixed(str: string): boolean {
  return str.substring(0, 2) === '0x';
}

export function checkHexValue(hex: string): void {
  assert(isHexPrefixed(hex), MISSING_HEX_PREFIX);
  const hexBn = new BN(encUtils.removeHexPrefix(hex), 16);
  assert(hexBn.gte(ZERO_BN));
  assert(hexBn.lt(PRIME_BN));
}

export function getIntFromBits(
  hex: string,
  start: number,
  end: number | undefined = undefined,
): number {
  const bin = encUtils.hexToBinary(hex);
  const bits = bin.slice(start, end);
  const int = encUtils.binaryToNumber(bits);
  return int;
}

export function getAccountPath(
  layer: string,
  application: string,
  ethereumAddress: string,
  index: string,
): string {
  const layerHash = hashJS.sha256().update(layer).digest('hex');
  const applicationHash = hashJS.sha256().update(application).digest('hex');
  const layerInt = getIntFromBits(layerHash, -31);
  const applicationInt = getIntFromBits(applicationHash, -31);
  const ethAddressInt1 = getIntFromBits(ethereumAddress, -31);
  const ethAddressInt2 = getIntFromBits(ethereumAddress, -62, -31);
  return `m/2645'/${layerInt}'/${applicationInt}'/${ethAddressInt1}'/${ethAddressInt2}'/${index}`;
}

export function hashKeyWithIndex(key: string, index: number): BN {
  return new BN(
    hashJS
      .sha256()
      .update(
        encUtils.hexToBuffer(
          encUtils.removeHexPrefix(key) +
            encUtils.sanitizeBytes(encUtils.numberToHex(index), 2),
        ),
      )
      .digest('hex'),
    16,
  );
}

export function grindKey(privateKey: string): string {
  let i = 0;
  let key: BN = hashKeyWithIndex(privateKey, i);

  while (!key.lt(SECP_ORDER.sub(SECP_ORDER.mod(ORDER)))) {
    key = hashKeyWithIndex(key.toString(16), i);
    i = i++;
  }
  return key.mod(ORDER).toString('hex');
}

export function getKeyPair(privateKey: string): ec.KeyPair {
  return starkEc.keyFromPrivate(privateKey, 'hex');
}

export function getKeyPairFromPath(seed: string, path: string): ec.KeyPair {
  assert(isHexPrefixed(seed), MISSING_HEX_PREFIX);
  const privateKey = hdkey
    .fromMasterSeed(Buffer.from(seed.slice(2), 'hex')) // assuming seed is '0x...'
    .derivePath(path)
    .getWallet()
    .getPrivateKeyString();
  return getKeyPair(grindKey(privateKey));
}

export function getPublic(keyPair: ec.KeyPair, compressed = false): string {
  return keyPair.getPublic(compressed, 'hex');
}

export function getStarkPublicKey(keyPair: ec.KeyPair): string {
  return getPublic(keyPair, true);
}

export function getKeyPairFromPublicKey(publicKey: string): ec.KeyPair {
  return starkEc.keyFromPublic(encUtils.hexToArray(publicKey));
}

export function getKeyPairFromPrivateKey(privateKey: string): ec.KeyPair {
  return starkEc.keyFromPrivate(privateKey, 'hex');
}

export function getXCoordinate(publicKey: string): string {
  const keyPair = getKeyPairFromPublicKey(publicKey);
  return encUtils.sanitizeBytes((keyPair as any).pub.getX().toString(16), 2);
}

export function hexTuples(hash: string): RegExpMatchArray | null {
  return hash
    .slice(2)
    .toLowerCase()
    .match(/.{1,2}/g);
}

export function truncateMintable240(hex: string): string {
  const hexArr: any = hexTuples(hex);
  const bitArr: any = hexArr.map((val: string) => {
    return parseInt(val, 16);
  });

  const hexMask =
    '0x0000FFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF';
  const hexMaskArr: any = hexTuples(hexMask);
  const bitMaskArr: any = hexMaskArr.map((val: string) => {
    return parseInt(val, 16);
  });

  let bitOutputArr: any = [];
  let ctr = 32 - 1;
  while (ctr >= 0) {
    bitOutputArr[ctr] = bitwise.bits.and(
      bitwise.byte.read(bitArr[ctr]),
      bitwise.byte.read(bitMaskArr[ctr]),
    );
    ctr -= 1;
  }
  bitOutputArr = bitOutputArr.map((val: any) => {
    return val.join('');
  });
  bitOutputArr[0] = '00000100';

  const hexOutputArr: any = bitOutputArr.map((val: string) => {
    return parseInt(val, 2) > Math.pow(2, 4) - 1
      ? parseInt(val, 2).toString(16)
      : `0${parseInt(val, 2).toString(16)}`;
  });
  return `0x${hexOutputArr.join('')}`;
}

export function truncate250(hex: string): string {
  const hexArr: any = hexTuples(hex);
  const bitArr: any = hexArr.map((val: string) => {
    return parseInt(val, 16);
  });
  const hexMask =
    '0x03FFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF';
  const hexMaskArr: any = hexTuples(hexMask);
  const bitMaskArr: any = hexMaskArr.map((val: string) => {
    return parseInt(val, 16);
  });

  let bitOutputArr: any = [];
  let ctr = 32 - 1;
  while (ctr >= 0) {
    bitOutputArr[ctr] = bitwise.bits.and(
      bitwise.byte.read(bitArr[ctr]),
      bitwise.byte.read(bitMaskArr[ctr]),
    );
    ctr -= 1;
  }
  bitOutputArr = bitOutputArr.map((val: any) => {
    return val.join('');
  });
  const hexOutputArr: any = bitOutputArr.map((val: string) => {
    return parseInt(val, 2) > Math.pow(2, 4) - 1
      ? parseInt(val, 2).toString(16)
      : `0${parseInt(val, 2).toString(16)}`;
  });
  return `0x${hexOutputArr.join('')}`;
}

export function getAssetInfo(token: Token): string {
  let assetInfo: string;
  let tokenAddress: string;
  switch (token.type.toUpperCase()) {
    case 'ETH':
      assetInfo = encUtils.sanitizeHex(
        solidityKeccak256(['string'], ['ETH()']).slice(2, 10),
      );
      break;
    case 'ERC20':
      tokenAddress = (token as ERC20Token).data.tokenAddress;
      checkHexValue(tokenAddress);
      assetInfo = encUtils.sanitizeHex(
        solidityKeccak256(['string'], ['ERC20Token(address)']).slice(2, 10) +
          encUtils.padLeft(encUtils.removeHexPrefix(tokenAddress), 0x20 * 2),
      );
      break;
    case 'ERC721':
      tokenAddress = (token as ERC721Token).data.tokenAddress;
      checkHexValue(tokenAddress);
      assetInfo = encUtils.sanitizeHex(
        solidityKeccak256(['string'], ['ERC721Token(address,uint256)']).slice(
          2,
          10,
        ) + encUtils.padLeft(encUtils.removeHexPrefix(tokenAddress), 0x20 * 2),
      );
      break;
    case 'MINTABLE_ERC20':
      tokenAddress = (token as MintableERC20Token).data.tokenAddress;
      checkHexValue(tokenAddress);
      assetInfo = encUtils.sanitizeHex(
        solidityKeccak256(['string'], ['MintableERC20Token(address)']).slice(
          2,
          10,
        ) + encUtils.padLeft(encUtils.removeHexPrefix(tokenAddress), 0x20 * 2),
      );
      break;
    case 'MINTABLE_ERC721':
      tokenAddress = (token as MintableERC721Token).data.tokenAddress;
      checkHexValue(tokenAddress);
      assetInfo = encUtils.sanitizeHex(
        solidityKeccak256(
          ['string'],
          ['MintableERC721Token(address,uint256)'],
        ).slice(2, 10) +
          encUtils.padLeft(encUtils.removeHexPrefix(tokenAddress), 0x20 * 2),
      );
      break;
    default:
      throw new Error(`Unknown token type: ${token.type}`);
  }
  return assetInfo;
}

// NOTE: 'quantum' is only required when completing withdrawal.
export function getAssetType(token: Token, quantum = '1'): Bytes32 {
  return new Bytes32(
    truncate250(
      solidityKeccak256(['bytes', 'uint256'], [getAssetInfo(token), quantum]),
    ),
  );
}

export function getMintingBlob(id: string, blueprint: string): string {
  return encUtils.sanitizeHex(encUtils.utf8ToHex(`{${id}}:{${blueprint}}`));
}

export function getMintingBlobHash(id: string, blueprint: string): string {
  return encUtils.sanitizeHex(
    solidityKeccak256(
      ['bytes'],
      [encUtils.utf8ToArray(`{${id}}:{${blueprint}}`)],
    ),
  );
}

export function getAssetID(token: Token): Bytes32 {
  const assetType = getAssetType(token);
  if (
    token.type.toUpperCase() === 'ETH' ||
    token.type.toUpperCase() === 'ERC20'
  ) {
    return assetType;
  }
  if (token.type.toUpperCase() === 'ERC721') {
    token.data = (token as ERC721Token).data;
    const assetID = new Bytes32(
      truncate250(
        solidityKeccak256(
          ['string', 'uint256', 'uint256'],
          [NFT_ASSET_ID_PREFIX, assetType.toUint().val, token.data.tokenId],
        ),
      ),
    );
    return assetID;
  }
  token.data = (token as MintableERC721Token).data;
  const assetID = new Bytes32(
    truncateMintable240(
      solidityKeccak256(
        ['string', 'uint256', 'uint256'],
        [
          MINTABLE_ASSET_ID_PREFIX,
          assetType.toUint().val,
          getMintingBlobHash(token.data.id, token.data.blueprint),
        ],
      ),
    ),
  );
  return assetID;
}

export function parseTokenInput(token: Token | string): string {
  if (typeof token === 'string') {
    checkHexValue(token);
    return token;
  }
  return getAssetID(token).val;
}

export function pedersen(input: string | string[]) {
  let point = shiftPoint;
  for (let i = 0; i < input.length; i++) {
    let x = new BN(encUtils.removeHexPrefix(input[i]), 16);
    assert(x.gte(ZERO_BN) && x.lt(prime), `Invalid input: ${input[i]}`);
    for (let j = 0; j < 252; j++) {
      const pt = constantPoints[2 + i * 252 + j];
      assert(!point.getX().eq(pt.getX()));
      if (x.and(ONE_BN).toNumber() !== 0) {
        point = point.add(pt);
      }
      x = x.shrn(1);
    }
  }
  return point.getX().toString(16);
}

// [lowerBound, upperBound)
// inclusive of lower bound, exclusive of upper bound
export function assertInRange(lowerBound: BN, upperBound: BN, values: BN[]) {
  // assert values within the specified bit size in message encoding
  for (let i = 0; i < values.length; i++) {
    assert(values[i].gte(lowerBound));
    assert(values[i].lt(upperBound));
  }
}

export function convertBnAndAssertRange(
  instruction: Instruction | InstructionWithFee,
  vault0: string,
  vault1: string,
  amount0: string,
  amount1: string,
  nonce: string,
  expirationTimestamp: string,
  feeVault = '0',
  feeLimit = '0',
) {
  const instructionTypeBn = instructionEncodingMap[instruction];
  // buy / sell vaults in orders or sender / receiver vaults in transfers
  const vault0Bn = new BN(vault0);
  const vault1Bn = new BN(vault1);
  const amount0Bn = new BN(amount0, 10);
  const amount1Bn = new BN(amount1, 10);
  const nonceBn = new BN(nonce);
  // in hours since the Unix epoch
  const expirationTimestampBn = new BN(expirationTimestamp);
  // fee information for orders / transfers with fees
  const feeVaultBn = new BN(feeVault);
  const feeLimitBn = new BN(feeLimit, 10);

  // vaults and nonce are of size 31 bits
  assertInRange(ZERO_BN, TWO_POW_31_BN, [
    vault0Bn,
    vault1Bn,
    feeVaultBn,
    nonceBn,
  ]);
  // amounts and fee are of size 63 bits
  assertInRange(ZERO_BN, TWO_POW_63_BN, [amount0Bn, amount1Bn, feeLimitBn]);
  // expiration timestamp are of size 22 bits
  assertInRange(ZERO_BN, TWO_POW_22_BN, [expirationTimestampBn]);

  return {
    instructionTypeBn,
    vault0Bn,
    vault1Bn,
    amount0Bn,
    amount1Bn,
    nonceBn,
    expirationTimestampBn,
    feeVaultBn,
    feeLimitBn,
  };
}

export function serializeMessage(
  instructionTypeBn: BN,
  vault0Bn: BN,
  vault1Bn: BN,
  amount0Bn: BN,
  amount1Bn: BN,
  nonceBn: BN,
  expirationTimestampBn: BN,
): string {
  // we bit shift so the message is packed as below (without fees)
  //          instr vault0    vault1        amount0             amount1          nonce  timestamp
  //          +---+---------+---------+-------------------+-------------------+---------+-------+
  // #bits    | 4 |   31    |   31    |        63         |        63         |   31    |  22   |
  //          +---+---------+---------+-------------------+-------------------+---------+-------+
  // label      A      B         C             D                   E              F        G
  // see https://docs.starkware.co/starkex-v3/starkex-deep-dive/message-encodings/signatures
  let serialized = instructionTypeBn;
  // shift left by the number of bit required in the message
  serialized = serialized.ushln(31).add(vault0Bn);
  serialized = serialized.ushln(31).add(vault1Bn);
  serialized = serialized.ushln(63).add(amount0Bn);
  serialized = serialized.ushln(63).add(amount1Bn);
  serialized = serialized.ushln(31).add(nonceBn);
  serialized = serialized.ushln(22).add(expirationTimestampBn);
  return encUtils.sanitizeHex(serialized.toString(16));
}

export function formatMessage(
  instruction: Instruction,
  vault0: string,
  vault1: string,
  amount0: string,
  amount1: string,
  nonce: string,
  expirationTimestamp: string,
): string {
  const {
    instructionTypeBn,
    vault0Bn,
    vault1Bn,
    amount0Bn,
    amount1Bn,
    nonceBn,
    expirationTimestampBn,
  } = convertBnAndAssertRange(
    instruction,
    vault0,
    vault1,
    amount0,
    amount1,
    nonce,
    expirationTimestamp,
  );
  return serializeMessage(
    instructionTypeBn,
    vault0Bn,
    vault1Bn,
    amount0Bn,
    amount1Bn,
    nonceBn,
    expirationTimestampBn,
  );
}

export function formatMessageWithFee(
  instruction: InstructionWithFee,
  vault0: string,
  vault1: string,
  amount0: string,
  amount1: string,
  nonce: string,
  expirationTimestamp: string,
  feeVault: string,
  feeLimit: string,
): [string, string] {
  const {
    instructionTypeBn,
    vault0Bn,
    vault1Bn,
    amount0Bn,
    amount1Bn,
    nonceBn,
    expirationTimestampBn,
    feeVaultBn,
    feeLimitBn,
  } = convertBnAndAssertRange(
    instruction,
    vault0,
    vault1,
    amount0,
    amount1,
    nonce,
    expirationTimestamp,
    feeVault,
    feeLimit,
  );

  switch (instruction) {
    case 'orderWithFee':
      return serializeOrderMsgWithFee(
        instructionTypeBn,
        vault0Bn,
        vault1Bn,
        amount0Bn,
        amount1Bn,
        nonceBn,
        expirationTimestampBn,
        feeVaultBn,
        feeLimitBn,
      );
    case 'transferWithFee':
      throw Error('Not implemented yet');
    default:
      throw Error('invalid instruction type');
  }
}

export function serializeOrderMsgWithFee(
  instructionTypeBn: BN,
  vaultSellBn: BN,
  vaultBuyBn: BN,
  amountSellBn: BN,
  amountBuyBn: BN,
  nonceBn: BN,
  expirationTimestampBn: BN,
  feeVaultBn: BN,
  feeLimitBn: BN,
): [string, string] {
  // Left bit shifting to construct the 251 bit message below for serialized1
  //
  //          +-------+--------------+--------------+--------------+--------+
  // #bits    | 27    |   64         |   64         |      64      |   32   |
  //          +-------+--------------+--------------+--------------+--------+
  // label      A               B            C                 D       E
  // A: padding of zeros
  // B: quantizedAmount to be sold.
  // C: quantizedAmount to be bought
  // D: quantizedAmount to pay fees
  // E: nonce for the transaction.
  let serialized1 = amountSellBn;
  serialized1 = serialized1.ushln(64).add(amountBuyBn);
  serialized1 = serialized1.ushln(64).add(feeLimitBn);
  serialized1 = serialized1.ushln(32).add(nonceBn);

  // Left bit shifting to construct the 251 bit message below for serialized2
  //          +---+--------------+--------------+--------------+-----+-----+
  // #bits    | 10|   64         |   64         |         64   | 32  |  17 |
  //          +---+--------------+--------------+--------------+-----+-----+
  // label      A      B            C                 D           E     F
  // A:  order type
  // 3 for a Limit Order with Fees
  // B: vaultId from which the user wants to use to pay fees.
  // C: vaultId from which the user wants to take the sold asset
  // D: vaultId from which the user wants to receive the bought asset.
  // E: expirationTimestamp, in hours since the Unix epoch.
  // F: padding of zeros
  // see https://docs.starkware.co/starkex-v3/starkex-deep-dive/message-encodings/signatures
  let serialized2 = instructionTypeBn;
  serialized2 = serialized2.ushln(64).add(feeVaultBn);
  serialized2 = serialized2.ushln(64).add(vaultSellBn);
  serialized2 = serialized2.ushln(64).add(vaultBuyBn);
  serialized2 = serialized2.ushln(32).add(expirationTimestampBn);
  serialized2 = serialized2.ushln(17).add(ZERO_BN);

  const w4 = encUtils.sanitizeHex(serialized1.toString(16));
  const w5 = encUtils.sanitizeHex(serialized2.toString(16));
  return [w4, w5];
}

export function hashMessage(w1: string, w2: string, w3: string) {
  return pedersen([pedersen([w1, w2]), w3]);
}

export function getLimitOrderMsgWithFee({
  vaultSell,
  vaultBuy,
  amountSell,
  amountBuy,
  nonce,
  expirationTimestamp,
  tokenSell,
  tokenBuy,
  feeToken,
  feeVault,
  feeLimit,
}: LimitOrderWithFeeParams) {
  // assert tokens in range
  assertInRange(ZERO_BN, PRIME_BN, [
    new BN(encUtils.removeHexPrefix(tokenSell), 16),
    new BN(encUtils.removeHexPrefix(tokenBuy), 16),
    new BN(encUtils.removeHexPrefix(feeToken), 16),
  ]);
  // w1 is the assetId to be sold
  // w2 is the assetId to be bought
  // w3 is the assetId used to pay the fee
  const w1 = tokenSell;
  const w2 = tokenBuy;
  const w3 = feeToken;
  // there are more words to encode for instructions with fees so a
  // temp hash is used to keep the message within the max 256 bit field size
  const tempHash = hashMessage(w1, w2, w3);
  // definitions for w4 and w5 defined in formatMessageWithFee
  const [w4, w5] = formatMessageWithFee(
    'orderWithFee',
    vaultSell,
    vaultBuy,
    amountSell,
    amountBuy,
    nonce,
    expirationTimestamp,
    feeVault,
    feeLimit,
  );
  // message to sign: H(H(H(H(w1,w2),w3),w4),w5)
  const hash = hashMessage(tempHash, w4, w5);
  assertInRange(ZERO_BN, MAX_ECDSA_BN, [
    new BN(encUtils.removeHexPrefix(hash), 16),
  ]);
  return hash;
}

export function getLimitOrderMsg(
  vaultSell: string,
  vaultBuy: string,
  amountSell: string,
  amountBuy: string,
  tokenSell: string,
  tokenBuy: string,
  nonce: string,
  expirationTimestamp: string,
): string {
  const w1 = tokenSell;
  const w2 = tokenBuy;
  const w3 = formatMessage(
    'order',
    vaultSell,
    vaultBuy,
    amountSell,
    amountBuy,
    nonce,
    expirationTimestamp,
  );
  return hashMessage(w1, w2, w3);
}

export function getTransferMsg(
  amount: string,
  nonce: string,
  senderVaultId: string,
  assetId: string,
  receiverVaultId: string,
  receiverPublicKey: string,
  expirationTimestamp: string,
): string {
  const w1 = assetId;
  const w2 = parseTokenInput(receiverPublicKey);
  const w3 = formatMessage(
    'transfer',
    senderVaultId,
    receiverVaultId,
    amount,
    ZERO_BN.toString(),
    nonce,
    expirationTimestamp,
  );
  return hashMessage(w1, w2, w3);
}

export function packRegisterUserMsg(etherKey: string, nonce: string): string {
  let serialized = new BN('1000');
  serialized = serialized
    .ushln(160)
    .add(new BN(new BigNumber(etherKey).toFixed()));
  serialized = serialized.ushln(31).add(new BN(nonce));
  return encUtils.sanitizeHex(serialized.toString(16));
}

export function getRegisterUserMsg(
  etherKey: string,
  starkPublicKey: string,
  nonce: string,
): string {
  return pedersen([
    pedersen([starkPublicKey]),
    packRegisterUserMsg(etherKey, nonce),
  ]);
}

export function packRegisterUserMsgVerifyEth(etherKey: string): string {
  let serialized = new BN('1000');
  serialized = serialized
    .ushln(160)
    .add(new BN(new BigNumber(etherKey).toFixed()));
  return encUtils.sanitizeHex(serialized.toString(16));
}

export function getRegisterUserMsgVerifyEth(
  etherKey: string,
  starkPublicKey: string,
): string {
  return pedersen([
    pedersen([starkPublicKey]),
    packRegisterUserMsgVerifyEth(etherKey),
  ]);
}

export function getDepositMsg(
  amount: string,
  nonce: string,
  vaultId: string,
  assetId: string,
  starkPublicKey: string,
): string {
  const w2 = parseTokenInput(starkPublicKey);
  const w3 = formatMessage('deposit', '0', vaultId, '0', amount, nonce, '');
  return hashMessage(assetId, w2, w3);
}

export function getWithdrawMsg(
  amount: string,
  nonce: string,
  vaultId: string,
  assetId: string,
  starkPublicKey: string,
): string {
  const w1 = parseTokenInput(starkPublicKey);
  const w2 = formatMessage('withdraw', vaultId, '0', amount, '0', nonce, '');
  return hashMessage(assetId, w1, w2);
}

export function fixMessage(msg: string) {
  msg = encUtils.removeHexPrefix(msg);
  msg = new BN(msg, 16).toString(16);

  if (msg.length <= 62) {
    // In this case, msg should not be transformed, as the byteLength() is at most 31,
    // so delta < 0 (see _truncateToN).
    return msg;
  }
  assert(msg.length === 63);
  // In this case delta will be 4 so we perform a shift-left of 4 bits by adding a ZERO_BN.
  return `${msg}0`;
}

export function sign(keyPair: ec.KeyPair, msg: string): ec.Signature {
  return keyPair.sign(fixMessage(msg));
}

// function exportRecoveryParam(recoveryParam: number | null | undefined): string | null {
//     return typeof recoveryParam === 'number' ? new BN(recoveryParam).add(new BN(27)).toString(16) : null;
// }

export function serializeSignature(sig: SignatureOptions): string {
  return encUtils.addHexPrefix(
    encUtils.padLeft(sig.r.toString(16), 64) +
      encUtils.padLeft(sig.s.toString(16), 64),
    // exportRecoveryParam(sig.recoveryParam) || '',
  );
}

export function serializeEthSignature(sig: SignatureOptions): string {
  // This is because golang appends a recovery param
  // https://github.com/ethers-io/ethers.js/issues/823
  return encUtils.addHexPrefix(
    encUtils.padLeft(sig.r.toString(16), 64) +
      encUtils.padLeft(sig.s.toString(16), 64) +
      encUtils.padLeft(sig.recoveryParam?.toString(16) || '', 2),
  );
}

export function importRecoveryParam(v: string): number | undefined {
  return v.trim()
    ? new BN(v, 16).cmp(new BN(27)) !== -1
      ? new BN(v, 16).sub(new BN(27)).toNumber()
      : new BN(v, 16).toNumber()
    : undefined;
}

export function deserializeSignature(sig: string, size = 64): SignatureOptions {
  sig = encUtils.removeHexPrefix(sig);
  return {
    r: new BN(sig.substring(0, size), 'hex'),
    s: new BN(sig.substring(size, size * 2), 'hex'),
    recoveryParam: importRecoveryParam(sig.substring(size * 2, size * 2 + 2)),
  };
}

export function packCancelOrderMsg(orderId: string): string {
  let serialized = new BN('1003');
  serialized = serialized.ushln(64).add(new BN(orderId));
  return encUtils.sanitizeHex(serialized.toString(16));
}

export function getCancelOrderMsg(orderID: string): string {
  return pedersen([packCancelOrderMsg(orderID)]);
}

export async function signAuthHeader(
  timestamp: string,
  signer: Signer,
): Promise<string> {
  const signature = deserializeSignature(await signer.signMessage(timestamp));
  return serializeEthSignature(signature);
}
